<template>
  <div class="container animate__animated animate__fadeInUp" style="max-width: 1880px; margin-top: 1.5em">
    <div class="card">
      <div class="card-body">
        <h2>{{ $t('admin_help.title') }}</h2>
        <p style="font-size: 1.5em">{{ $t('admin_help.subtitle') }}</p>
        <h3>{{ $t('admin_help.dcodes') }}</h3>
        <p style="font-size: 1.5em">{{ $t('admin_help.dcodest') }}</p>
        <div>
          <div class="table-responsive">
            <table class="table table-sm table-hover">
              <thead style="background-color: #2596be; color: white">
              <tr>
                <th scope="col">{{ $t('download_codes.id') }}</th>
                <th scope="col">{{ $t('download_codes.platform') }}</th>
                <th scope="col">{{ $t('download_codes.author') }}</th>
                <th scope="col">{{ $t('download_codes.creation_date') }}</th>
                <th scope="col">{{ $t('download_codes.download_date') }}</th>
                <th scope="col">{{ $t('download_codes.connection_date') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>b96f7d7757664d638fc41ab2ef579471</th>
                <th>WIN</th>
                <th>admin</th>
                <th>01/05/2021 10:49:16</th>
                <th>08/05/2021 12:26:51
                  <button class="btn btn-sm btn-success" style="margin-left: 0.5em;">
                    {{ $t('download_codes.enable') }}
                  </button>
                </th>
                <th>{{ $t('download_codes.not_available') }}
                  <button class="btn btn-sm btn-danger" style="margin-left: 0.5em;"
                  >
                    {{ $t('download_codes.disable') }}
                  </button>
                </th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <h3 style="margin-top: 1em">{{ $t('admin_help.logs') }}</h3>
        <p style="font-size: 1.5em">{{ $t('admin_help.logst') }}</p>
        <h3>{{ $t('admin_help.accounts') }}</h3>
        <p style="font-size: 1.5em">{{ $t('admin_help.accounts1') }}</p>
        <span style="font-size: 1.5em">
          <li><b>{{ $t('admin_help.admin') }}:</b> {{ $t('admin_help.admint') }}</li>
          <li><b>{{ $t('admin_help.tech') }}:</b> {{ $t('admin_help.techt') }}</li>
          <li><b>{{ $t('admin_help.demo') }}:</b> {{ $t('admin_help.demot') }}</li>
        </span>
        <p style="font-size: 1.5em; margin-top: 0.5em">{{ $t('admin_help.account2') }}</p>
        <span style="font-size: 1.5em">
          <li>{{ $t('admin_help.editdata') }}</li>
          <li>{{ $t('admin_help.deactacc') }}</li>
          <li>{{ $t('admin_help.reactacc') }}</li>
          <li>{{ $t('admin_help.regenpswd') }}</li>
          <li>{{ $t('admin_help.deactacc') }}</li>
        </span>
        <p style="font-size: 1.5em; margin-top: 0.5em">{{ $t('admin_help.butto') }}</p>
        <div class="row row-cols-1 row-cols-sm-5">
          <div class="col">
            <button class="btn btn-sm btn-warning" style="margin-left: 0.5em"
            ><i class="fas fa-edit"
                style="color: white"></i>
            </button>
            <p style="font-size: 1.5em">{{ $t('admin_help.editbt') }}</p>
          </div>
          <div class="col">
            <button class="btn btn-sm btn-success" style="margin-left: 0.5em;"
            ><i class="fas fa-save"
                style="color: white"></i>
            </button>
            <p style="font-size: 1.5em">{{ $t('admin_help.savebt') }}</p>
          </div>
          <div class="col">
            <button class="btn btn-sm btn-primary" style="margin-left: 0.5em;"><i
                class="fas fa-trash-restore"></i>
            </button>
            <p style="font-size: 1.5em">{{ $t('admin_help.reabt') }}</p>
          </div>
          <div class="col">
            <button class="btn btn-sm btn-danger" style="margin-left: 0.5em;"><i
                class="fas fa-trash"></i>
            </button>
            <p style="font-size: 1.5em">{{ $t('admin_help.deabt') }}</p>
          </div>
          <div class="col">
            <button class="btn btn-sm btn-secondary" style="margin-left: 0.5em;"><i
                  class="fas fa-redo-alt"></i>
            </button>
            <p style="font-size: 1.5em">{{ $t('admin_help.regbt') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminHelp"
}
</script>

<style scoped>

</style>