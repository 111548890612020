<template>
  <div class="container-xl animate__animated animate__slideInUp" style="margin-top: 1.5em; max-width: 1880px !important; margin-bottom: 0.5em">
    <div class="card">
      <div class="card-body">
        <h5>{{ $t('processes.title') }}</h5>
        <div class="table-responsive" style="border-radius: 5px" v-if="processes.length > 0" id="tproct">
          <table class="table table-sm table-dark table-hover">
            <thead>
            <tr>
              <th scope="col" @click="sort('pid')"><h4>{{ $t('processes.pid') }} <span v-if="currentSort == 'pid'"><i
                  v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                        v-if="sortAsc == false"></i></span></h4></th>
              <th scope="col" @click="sort('name')"><h4>{{ $t('processes.name') }} <span v-if="currentSort == 'name'"><i
                  v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                        v-if="sortAsc == false"></i></span></h4></th>
              <th scope="col" @click="sort('status')"><h4>{{ $t('processes.status') }} <span
                  v-if="currentSort == 'status'"><i
                  v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                        v-if="sortAsc == false"></i></span></h4></th>
              <th scope="col" @click="sort('cpu_usage')"><h4>{{ $t('processes.cpu') }} <span
                  v-if="currentSort == 'cpu_usage'"><i
                  v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                        v-if="sortAsc == false"></i></span></h4></th>
              <th scope="col" style="min-width: 8em" @click="sort('cpu_affinity')"><h4>{{ $t('processes.cpu_af') }}
                <span v-if="currentSort == 'cpu_affinity'"><i
                    v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                          v-if="sortAsc == false"></i></span></h4>
              </th>
              <th scope="col" @click="sort('priority')"><h4>{{ $t('processes.priority') }} <span
                  v-if="currentSort == 'priority'"><i
                  v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                        v-if="sortAsc == false"></i></span></h4></th>
              <th scope="col" @click="sort('memory_usage')"><h4>{{ $t('processes.memory') }} <span
                  v-if="currentSort == 'memory_usage'"><i
                  v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                        v-if="sortAsc == false"></i></span></h4></th>
              <th scope="col" @click="sort('disk_usage')"><h4>{{ $t('processes.disk') }} <span
                  v-if="currentSort == 'disk_usage'"><i
                  v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                        v-if="sortAsc == false"></i></span></h4></th>
              <th scope="col" @click="sort('n_threads')"><h4>{{ $t('processes.threads') }} <span
                  v-if="currentSort == 'n_threads'"><i
                  v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                        v-if="sortAsc == false"></i></span></h4></th>
              <th scope="col" style="min-width: 10em" @click="sort('create_time')"><h4>{{
                  $t('processes.creation')
                }} <span v-if="currentSort == 'create_time'"><i
                    v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                          v-if="sortAsc == false"></i></span></h4></th>
              <th scope="col" style="min-width: 12em" @click="sort('username')"><h4>{{ $t('processes.user') }} <span
                  v-if="currentSort == 'username'"><i
                  v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                        v-if="sortAsc == false"></i></span></h4></th>
              <th scope="col" style="max-width: 15em" @click="sort('path')"><h4>{{ $t('processes.path') }} <span
                  v-if="currentSort == 'path'"><i
                  v-if="sortAsc == true" class="fas fa-caret-up"></i><i class="fas fa-caret-down"
                                                                        v-if="sortAsc == false"></i></span></h4></th>
            </tr>
            </thead>
            <tbody>
            <tr class="animate__animated animate__fadeIn" v-for="(process, index) in processes" :key="index">
              <td>{{ process.pid }}</td>
              <td>{{ process.name }}</td>
              <td>{{ process.status }}</td>
              <td>{{ process.cpu_usage }}%</td>
              <td>{{ process.cpu_affinity }}</td>
              <td>{{ process.priority }}</td>
              <td>{{ process.memory_usage }} MB</td>
              <td>{{ process.disk_usage }}%</td>
              <td>{{ process.n_threads }}</td>
              <td>{{ process.create_time }}</td>
              <td>{{ process.username }}</td>
              <td style="max-width: 20em; overflow: auto">{{ process.path }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-if="processes.length == 0" style="text-align: center" class="animate__animated animate__zoomIn">
          <div class="spinner-border text-info" role="status" style="height: 8em; width: 8em">
            <span><img src="https://leroderic.github.io/squid_remote/media/favicon.png" style="width: 7em"></span>
          </div>
          <h3 style="margin-top: 1em">{{ $t('dashboard.waiting_data') }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import * as toastr from "@/assets/toastr";
import {api} from "@/main";
import axios from "axios";

export default {
  name: "ProcessMonitoring",
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    machine_id: Number
  },
  data() {
    return {
      processes: [],
      machine: {},
      currentSort: "memory_usage",
      sortAsc: false
    }
  }, methods: {
    sort(val) {
      if (this.currentSort == val) {
        this.sortAsc = !this.sortAsc
      } else {
        this.currentSort = val
      }
      this.sortProcesses()
    },
    sortProcesses() {
      this.processes = this.processes.sort((a, b) => {
        let modifier = 1
        if (!this.sortAsc) {
          modifier = -1
        }
        if (a[this.currentSort] < b[this.currentSort]) {
          return -1 * modifier
        }
        if (a[this.currentSort] > b[this.currentSort]) {
          return 1 * modifier
        }
        return 0
      })
    },
    getMachineTriggerInit(id) {
      const path = api + 'machine/' + id
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.machine = res.data.machine
            this.subscribeBindChannels()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    getProcesses() {
      const path = api + 'machine/' + this.machine.mac_address + '/processes'
      axios.get(path, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.processes = res.data.processes
            this.sortProcesses()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    processMonitoringChannel() {
      var channel = this.pusher.subscribe('private-' + this.machine.mac_address.replace(/:/g, ""))
      // eslint-disable-next-line no-unused-vars
      var handler = function () {
        this.getProcesses()
      }
      channel.bind("processes_monitoring", this.getProcesses)
    },
    subscribeBindChannels() {
      this.processMonitoringChannel()
    }
  },
  created() {
    scrollTo(0, 0)
    this.getMachineTriggerInit(this.machine_id)
  },
  mounted() {
  }
}
</script>

<style scoped>
th {
  position: sticky;
}

tbody tr:nth-child(odd) {
  background-color: #3f5259;
  color: #fff;
}

tbody tr:nth-child(even) {
  background-color: #2e373b;
  color: #fff;
}

::-webkit-scrollbar {
  height: 0.5em !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c81a1;
  border-radius: 10px;
}

</style>