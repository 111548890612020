<style>
@import url("../assets/animate.min.css");
@import url("../assets/toastr.css");
@import url("../assets/flags.css");
</style>
<template>
  <div>
    <div style="background-color: #191c1e">
      <div class="container compressed-container" style="max-width: 1880px" id="container"
           :style="{'margin-bottom': navHeight + 'px'}">
        <nav class="navbar fixed-top navbar-expand-md navbar-dark bg-dark" style="background-color: #191c1e !important"
             id="dashboard-nav">
        <span class="navbar-nav mr-auto">
          <img class="navbar-brand animate__animated animate__flip"
               src="https://leroderic.github.io/squid_remote/media/favicon.png"
               style="height: 45px; margin: 0">
          </span>
          <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)" v-if="selected_mode != 0"
                   style="text-align: center" @click="monitorProcesses" data-toggle="collapse" data-target=".dual-collapse2">{{ $t('dashboard.processes') }}</a>
                <a class="nav-link active" href="javascript:void(0)" v-if="selected_mode == 0"
                   style="text-align: center; color: #0c81a1" data-toggle="collapse" data-target=".dual-collapse2"><b>{{ $t('dashboard.processes') }}</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)" v-if="selected_mode != 1"
                   style="text-align: center" @click="monitorNetworkTraffic" data-toggle="collapse" data-target=".dual-collapse2">{{ $t('dashboard.network') }}</a>
                <a class="nav-link active" href="javascript:void(0)" v-if="selected_mode == 1"
                   style="text-align: center; color: #0c81a1" data-toggle="collapse" data-target=".dual-collapse2"><b>{{ $t('dashboard.network') }}</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)" v-if="selected_mode != 2"
                   style="text-align: center" @click="monitorDevices" data-toggle="collapse" data-target=".dual-collapse2">{{ $t('dashboard.devices') }}</a>
                <a class="nav-link active" href="javascript:void(0)" v-if="selected_mode == 2"
                   style="text-align: center; color: #0c81a1" data-toggle="collapse" data-target=".dual-collapse2"><b>{{ $t('dashboard.devices') }}</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)" v-if="selected_mode != 3"
                   style="text-align: center" @click="monitorPerformance" data-toggle="collapse" data-target=".dual-collapse2">{{ $t('dashboard.performance') }}</a>
                <a class="nav-link active" href="javascript:void(0)" v-if="selected_mode == 3"
                   style="text-align: center; color: #0c81a1" data-toggle="collapse" data-target=".dual-collapse2"><b>{{ $t('dashboard.performance') }}</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)" v-if="selected_mode != 4"
                   style="text-align: center" @click="reverseShell" data-toggle="collapse" data-target=".dual-collapse2">{{ $t('dashboard.shell') }}</a>
                <a class="nav-link active" href="javascript:void(0)" v-if="selected_mode == 4"
                   style="text-align: center; color: #0c81a1" data-toggle="collapse" data-target=".dual-collapse2"><b>{{ $t('dashboard.shell') }}</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)" v-if="selected_mode != -1"
                   style="text-align: center" @click="selected_mode = -1" data-toggle="collapse" data-target=".dual-collapse2">{{ $t('dashboard.help') }}</a>
                <a class="nav-link active" href="javascript:void(0)" v-if="selected_mode == -1"
                   style="text-align: center; color: #0c81a1" data-toggle="collapse" data-target=".dual-collapse2"><b>{{ $t('dashboard.help') }}</b></a>
              </li>
            </ul>
          </div>
          <div class="mx-auto order-0">
            <a class="navbar-brand ml-auto">{{ machine.mac_address }}</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2"
                    style="margin-right: -1.5em">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item" data-toggle="collapse" data-target=".dual-collapse2">
                <a class="nav-link" href="javascript:void(0)"
                   style="text-align: center" @click="showDialogMessage(machine)">{{ $t('dashboard.send_msg') }}</a>
              </li>
              <li class="nav-item" data-toggle="collapse" data-target=".dual-collapse2">
                <a class="nav-link" href="javascript:void(0)"
                   style="text-align: center" @click="startPingOnMachine">{{ $t('dashboard.test_connection') }}</a>
              </li>
              <li class="nav-item" data-toggle="collapse" data-target=".dual-collapse2">
                <a class="nav-link" style="text-align: center" type="button"
                   data-target="#navbarToggleExternalContent" data-toggle="collapse"
                   aria-controls="navbarToggleExternalContent" aria-expanded="true"
                   aria-label="Toggle navigation">{{ $t('dashboard.machine_info') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)"
                   style="text-align: center" @click="goToMain">{{ $t('dashboard.back_to_machines') }}</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false" style="text-align: center">
                  {{ $t('dashboard.actions') }}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="javascript:void(0)" hidden>{{ $t('dashboard.shutdown') }}</a>
                  <a class="dropdown-item" href="javascript:void(0)" hidden>{{ $t('dashboard.restart') }}</a>
                  <div class="dropdown-divider" hidden></div>
                  <a class="dropdown-item" href="#" style="color: red"
                     @click="disconnectRemoteMachine(machine)">{{ $t('dashboard.disconnect') }}</a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="javascript:void(0)"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-align: center">
                  <span class="flag flag-es" v-if="lang == 'ES'"></span>
                  <span class="flag flag-gb" v-if="lang == 'EN'"></span>
                  <span class="flag flag-catalonia" v-if="lang == 'CA'"></span>
                </a>
                <div class="dropdown-menu" style="min-width: inherit"
                     aria-labelledby="dropdown09">
                  <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'EN'"
                     @click="updateLang('EN')" data-toggle="collapse" data-target=".dual-collapse2"><span
                      class="flag flag-gb" style="text-align: center"></span></a>
                  <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'ES'"
                     @click="updateLang('ES')" data-toggle="collapse" data-target=".dual-collapse2"><span
                      class="flag flag-es" style="text-align: center"></span></a>
                  <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'CA'"
                     @click="updateLang('CA')" data-toggle="collapse" data-target=".dual-collapse2"><span
                      class="flag flag-catalonia" style="text-align: center"></span></a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="pos-f-t">
        <div class="collapse" id="navbarToggleExternalContent">
          <div class="bg-dark p-4" style="background-color: #373A3E !important;">
            <div class="row row-cols-1 row-cols-md-5">
              <div class="col">
                <h4 style="color: white">Equipo</h4>
                <h6 style="color: white"><span style="color: #0983a3">Hostname:</span> {{ machine['hostname'] }}</h6>
                <h6 style="color: white"><span style="color: #0983a3">Arquitectura:</span> {{ machine['architecture'] }}
                </h6>
                <h6 style="color: white"><span style="color: #0983a3">Procesador:</span> {{ machine['processor'] }}</h6>
                <h6 style="color: white"><span style="color: #0983a3">Memoria RAM:</span> {{ machine.ram }}</h6>
              </div>
              <div class="col">
                <h4 style="color: white">Sistema operativo</h4>
                <h6 style="color: white"><span style="color: #0983a3">Plataforma:</span> {{ machine['platform'] }}</h6>
                <h6 style="color: white"><span style="color: #0983a3">Release plataforma:</span>
                  {{ machine['platform_release'] }}</h6>
                <h6 style="color: white"><span style="color: #0983a3">Versión plataforma:</span>
                  {{ machine['platform_version'] }}</h6>
              </div>
              <div class="col">
                <h4 style="color: white">Tarjetas de red</h4>
                <div style="max-height: 8.5em; overflow-y: auto">
                  <div v-for="card in machine.network_cards" :key="card">
                    <h6 style="color: #0983a3">{{ card.card_name }}</h6>
                    <h7 style="color: white">{{ card.ip_v4 }}<br></h7>
                    <h7 style="color: white">{{ card.ip_v6 }}</h7>
                  </div>
                </div>
              </div>
              <div class="col">
                <h4 style="color: white">Incidencia reportada</h4>
                <h6 style="color: white"><span style="color: #0983a3">Incidente:</span> {{ machine.incident }}</h6>
                <h6 style="color: white"><span style="color: #0983a3">Fecha y hora:</span> {{ machine.incident_date }}
                </h6>
                <h6 style="color: white"><span style="color: #0983a3">Más información:</span>
                  {{ machine.incident_info }}</h6>
              </div>
              <div class="col">
                <h4 style="color: white">Contacto</h4>
                <h6 style="color: white"><span style="color: #0983a3">Nombre y apellidos:</span> {{ machine.contact }}
                </h6>
                <h6 style="color: white"><span style="color: #0983a3">Télefono:</span> {{ machine.contact_tel }}</h6>
                <h6 style="color: white"><span style="color: #0983a3">Correo electrónico:</span> <a
                    :href="'mailto:' + machine.contact_email">{{ machine.contact_email }}</a></h6>
                <h6 style="color: white"><span style="color: #0983a3">Más información:</span> {{ machine.contact_info }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" :id="'sendMessageMachine' + machine.id" tabindex="-1" role="dialog"
           aria-labelledby="exampleModalLabel"
           aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Enviar mensaje a máquina</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <textarea class="form-control" rows="4" maxlength="500" :id="'msgText' + machine.id"></textarea>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              <button type="button" class="btn btn-primary" style="background-color: #0983a3"
                      @click="sendMessageMachine(machine)">
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="progress" style="height: 0.5em; visibility: hidden" id="pingProgressDiv">
        <div class="progress-bar" role="progressbar" id="pingProgressBar"
             style="background-color: #0983a3; width: 0%"
             aria-valuemax="100"></div>
      </div>
    </div>
    <startup-screen v-show="selected_mode == -1" :lang="lang"></startup-screen>
    <process-monitoring v-show="selected_mode == 0" :id="id" :type="type" :token="token" :pusher="pusher"
                        :machine_id="$route.params.id"></process-monitoring>
    <NetworkTrafficMonitor v-show="selected_mode == 1" :id="id" :type="type" :token="token" :pusher="pusher"
                   :machine_id="$route.params.id"></NetworkTrafficMonitor>
    <DeviceMonitoring v-show="selected_mode == 2" :id="id" :type="type" :token="token" :pusher="pusher"
                   :machine_id="$route.params.id"></DeviceMonitoring>
    <HardwareMonitoring v-show="selected_mode == 3" :id="id" :type="type" :token="token" :pusher="pusher"
                   :machine_id="$route.params.id"></HardwareMonitoring>
    <reverse-shell v-show="selected_mode == 4" :id="id" :type="type" :token="token" :pusher="pusher"
                   :machine_id="$route.params.id"></reverse-shell>
    <footer style="text-align: center">
      {{ $t('dashboard.copyright') }} {{ getYear() }} <span style="font-family: 'logo_font'">squid</span>
    </footer>
  </div>
</template>

<script>
import {api, bus} from "@/main";
import axios from "axios";
import $ from "jquery";
import ProcessMonitoring from "@/components/ProcessMonitoring";
// eslint-disable-next-line no-unused-vars
import * as toastr from "@/assets/toastr";
import ReverseShell from "@/components/ReverseShell";
import NetworkTrafficMonitor from "@/components/NetworkTrafficMonitor";
import DeviceMonitoring from "./DeviceMonitoring";
import HardwareMonitoring from "./HardwareMonitoring";
import StartupScreen from "./StartupScreen";

export default {
  name: "MachineDashBoard",
  components: {
    StartupScreen,
    HardwareMonitoring, DeviceMonitoring, NetworkTrafficMonitor, ReverseShell, ProcessMonitoring},
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    lang: String
  },
  data() {
    return {
      pingTestInProgress: false,
      selected_mode: -1, //-1
      machine: [],
      navHeight: 0
    }
  },
  created() {
    scrollTo(0, 0)
  },
  mounted() {
    this.getMachine(this.$route.params.id)
    this.navHeight = document.getElementById('dashboard-nav').offsetHeight
  },
  methods: {
    updateLang(l) {
      // eslint-disable-next-line vue/no-mutating-props
      bus.emit('lang-updated', {
        'lang': l,
      })
    },
    pingTestMachineNoResponse() {
      toastr.error('', this.$t("dashboard.verify_con_failed"),
          {
            timeOut: 1500,
            progressBar: true,
            newestOnTop: true,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
          })
      document.getElementById("container").classList.add("compressed-container")
      document.getElementById("pingProgressDiv").style.visibility = "hidden"
    },
    startPingOnMachine() {
      if (document.getElementById("pingProgressDiv").style.visibility != "hidden") {
        return 0
      }
      document.getElementById("container").classList.remove("compressed-container")
      document.getElementById("pingProgressDiv").style.visibility = "visible"
      this.changeOpCode(this.machine, 0, 'PNG-5')
      var channel = this.pusher.subscribe('private-' + this.machine.mac_address.replace(/:/g, ""))
      var errorTimeOut = setTimeout(() => {
        this.pingTestMachineNoResponse()
      }, 10000)
      channel.unbind("ping_test")
      channel.bind("ping_test", function (data) {
        var current = data["current_ping"]
        current = parseInt(current.slice(-1))

        if (current == 4) {
          this.w.clearTimeout(this.error_to)
        }

        this.progress.style.width = String(100 - 20 * current) + "%"

        if (current == 0) {
          this.toastr.success('', this.msg_ok,
              {
                timeOut: 1500,
                progressBar: true,
                newestOnTop: true,
                positionClass: 'toast-bottom-right',
                preventDuplicates: true
              })
          this.progress.style.width = "0%"
          this.progress_div.style.visibility = "hidden"
          this.cont.classList.add("compressed-container")
        }
      }, {
        channel: channel,
        cont: document.getElementById("container"),
        progress: document.getElementById("pingProgressBar"),
        toastr: toastr,
        progress_div: document.getElementById("pingProgressDiv"),
        error_to: errorTimeOut,
        w: window,
        msg_ok: this.$t("dashboard.verify_con_success")
      })
    },
    goToMain() {
      this.changeOpCode(this.machine, 1, 0)
      this.changeOpCode(this.machine, 2, "CMD-TER-1")
      this.$router.push('/')
    },
    changeOpCode(m, n, opcode) {
      const path = api + 'machine/' + m.mac_address + '/' + n
      axios.post(path, {"opcode": opcode, "account_id": this.id}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    disconnectRemoteMachine(m) {
      this.changeOpCode(m, 2, -1)
      this.$router.push('/')
    },
    showDialogMessage(m) {
      $("#sendMessageMachine" + m.id).modal('show')
    },
    sendMessageMachine(m) {
      const path = api + 'machine/' + m.mac_address + '/message'
      axios.post(path, {"message": document.getElementById("msgText" + m.id).value}, {
        auth: {
          username: this.id,
          password: this.token
        }
      })
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            $("#sendMessageMachine" + m.id).modal('hide')
            document.getElementById("msgText" + m.id).value = ""
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    monitorProcesses() {
      this.selected_mode = 0
      this.changeOpCode(this.machine, 1, "PRC")
    },
    monitorNetworkTraffic() {
      this.selected_mode = 1
      this.changeOpCode(this.machine, 1, "0")
    },
    monitorDevices() {
      this.selected_mode = 2
      this.changeOpCode(this.machine, 1, "0")
    },
    monitorPerformance() {
      this.selected_mode = 3
      this.changeOpCode(this.machine, 1, "SYS")
    },
    reverseShell() {
      this.selected_mode = 4
      this.changeOpCode(this.machine, 1, "0")
    },
    getMachine(id) {
      const path = api + 'machine/' + id
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.machine = res.data.machine
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    getYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
@font-face {
  src: url("../assets/namo.ttf");
  font-family: "logo_font";
}

footer {
  position: relative;
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 450px) {
  footer {
    position: relative;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0.5em;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c81a1;
  border-radius: 10px;
}

.compressed-container {
  margin-top: -0.5em;
}
</style>