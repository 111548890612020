<template>
  <div class="container animate__animated animate__fadeInUp" style="max-width: 1880px; margin-top: 1.5em">
    <div class="card">
      <div class="card-body">
        <h2>{{ $t('main_help.title') }}</h2>
        <p style="font-size: 1.5em">{{ $t('main_help.subtitle') }}</p>
        <h3>{{ $t('main_help.navbar') }}</h3>
        <p style="font-size: 1.5em">{{ $t('main_help.navbar_text') }}</p>
        <span style="font-size: 1.5em">
        <div class="row row-cols-1 row-cols-sm-2">
          <div class="col">
            <li>{{ $t('main_help.sendDL') }}</li>
            <li>{{ $t('main_help.refresh') }}</li>
            <li>{{ $t('main_help.admin') }}</li>
          </div>
          <div class="col">
            <li>{{ $t('main_help.closeHelp') }}</li>
            <li>{{ $t('main_help.closeSession') }}</li>
          </div>
        </div>
        </span>
        <h3 style="margin-top: 1em">{{ $t('main_help.managing_machine') }}</h3>
        <p style="font-size: 1.5em">{{ $t('main_help.machine1') }}</p>
        <div class="row row-cols-1 row-cols-sm-3">
          <div class="col">
            <li><span style="font-size: 1.5em">Edit button:</span>
              <button class="btn btn-sm btn-warning" style="margin-left: 0.5em"
              ><i class="fas fa-edit"
                  style="color: white"></i>
              </button>
            </li>
          </div>
          <div class="col">
            <li><span style="font-size: 1.5em">Save button:</span>
              <button class="btn btn-sm btn-success" style="margin-left: 0.5em"
              ><i class="fas fa-save"
                  style="color: white"></i>
              </button>
            </li>
          </div>
          <div class="col">
            <li><span style="font-size: 1.5em">Delete button:</span>
              <button class="btn btn-sm btn-danger" style="margin-left: 0.5em"
              ><i class="fas fa-trash"
                  style="color: white"></i>
              </button>
            </li>
          </div>
        </div>
        <p style="font-size: 1.5em">{{ $t('main_help.machine2') }}</p>
        <div class="row row-cols-1 row-cols-sm-4">
          <div class="col">
            <button type="button" class="btn btn-outline-primary"
                    style="margin-right: 0.5em; margin-top: 0.5em"
            >
              {{ $t('main.connect') }}
            </button>
            <p style="font-size: 1.5em">{{ $t('main_help.con') }}</p>
          </div>
          <div class="col">
            <button type="button" class="btn btn-outline-secondary"
                    style="margin-right: 0.5em; margin-top: 0.5em"
            >{{ $t('main.verify_con') }}
            </button>
            <p style="font-size: 1.5em">{{ $t('main_help.test') }}</p>
          </div>
          <div class="col">
            <button type="button" class="btn btn-outline-warning"
                    style="margin-right: 0.5em; margin-top: 0.5em"
            >
              {{ $t('main.send_msg') }}
            </button>
            <p style="font-size: 1.5em">{{ $t('main_help.msg') }}</p>
          </div>
          <div class="col">
            <button type="button" class="btn btn-danger" style="margin-top: 0.5em"
            >
              {{ $t('main.disconnect') }}
            </button>
            <p style="font-size: 1.5em">{{ $t('main_help.discon') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpMain"
}
</script>

<style scoped>

</style>