<style>
@import url("./assets/animate.min.css");
@import url("./assets/toastr.css");
</style>
<template>
  <body>
  <div class="modal fade" id="modalAboutToExpire" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Sesión a punto de terminar</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          La sesión terminará dentro de 5 minutos. Puedes extenderla o expirarla, en cuyo caso deberás acceder de nuevo
          al sistema.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" style="background-color: #0983a3" @click="extendSession">
            Extender sesión
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="modalExpired" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Sesión expirada</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Debes acceder de nuevo al sistema para seguir usándolo.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  <router-view :id="this.idIn" :type="this.typeIn" :token="this.tokenIn" :pusher="this.pusherIn"
               :lang="this.langIn"></router-view>
  </body>
</template>

<script>
import Login from "@/components/Login";
import {api, bus, SECURE_COOKIES} from "./main.js"
import $ from 'jquery'
import axios from "axios";
import * as toastr from "@/assets/toastr";

const Pusher = require('pusher-js');

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Login
  },
  data() {
    return {
      tokenIn: '',
      typeIn: '',
      idIn: '',
      pusherIn: null,
      langIn: "EN"
    }
  },
  created() {
    bus.on('has-logged-in', (asd) => {
      this.tokenIn = String(asd.token)
      this.typeIn = parseInt(asd.type)
      this.idIn = parseInt(asd.id)
      this.pusherIn = this.initPusherInstance()
      this.setSessionExpiresAlert()
    })
    bus.on('lang-updated', (asd) => {
      this.langIn = String(asd.lang)
      this.saveLagPref({lang: this.langIn})
      this.$i18n.locale = String(asd.lang)
    })
    if (this.$cookie.isCookieAvailable("lpref")){
      var data = this.$cookie.getCookie("lpref")
      this.langIn = data.lang
      this.$i18n.locale = data.lang
    }
  }, methods: {
    initPusherInstance() {
      return new Pusher('b67af0933a541df6a69a', {
            cluster: 'eu',
            authEndpoint: api + 'pusher/auth',
            auth: {
              headers: {
                'Authorization': 'Basic ' + window.btoa(this.idIn + ':' + this.tokenIn)
              }
            }
          }
      )
    },
    extendSession() {
      const path = api + 'session/extend'
      axios.post(path, {"id": this.idIn, "token": this.tokenIn}, {auth: {username: this.idIn, password: this.tokenIn}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            $('#modalAboutToExpire').modal('hide')
            this.saveLogInDataCookie({
              token: String(res.data.token.toString()), type: parseInt(res.data.type),
              id: res.data.id
            })
            bus.emit('has-logged-in', {
              'token': String(res.data.token.toString()),
              'type': parseInt(res.data.type),
              'id': res.data.id
            })
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
            toastr.error('', 'No se ha podido guardar la información.',
                {
                  timeOut: 1500,
                  progressBar: true,
                  newestOnTop: true,
                  positionClass: 'toast-bottom-right',
                  preventDuplicates: true
                })
          })
    }
    ,
    saveLagPref(data) {
      this.$cookie.setCookie("lpref", data, {
        expire: '120d',
        path: '/',
        domain: '',
        secure: SECURE_COOKIES, //true
        sameSite: '',
      })
    },
    saveLogInDataCookie(data) {
      this.$cookie.setCookie("srld", data, {
        expire: '2h',
        path: '/',
        domain: '',
        secure: SECURE_COOKIES, //true
        sameSite: '',
      })
    },
    setSessionExpiresAlert() {
      this.clearAllTimeOuts()
      setTimeout(function () {
        $('#modalAboutToExpire').modal('show')
      }, 55 * 60000)
      setTimeout(this.timeOutLogOut, 60 * 60000)
    }
    ,
    clearAllTimeOuts() {
      var id = window.setTimeout(function () {
      }, 0);
      while (id--) {
        window.clearTimeout(id); // will do nothing if no timeout with id is present
      }
    }
    ,
    timeOutLogOut() {
      this.$router.push('/')
      if (this.$cookie.isCookieAvailable("srld")) {
        this.$cookie.removeCookie("srld")
      }
      $('#modalAboutToExpire').modal('hide')
      $('#modalExpired').modal('show')
    }
  },
  mounted() {

  }
}
</script>

<style>
html, body {
  height: 100vh;
}

</style>
