<template>
  <div>
    <div class="modal fade" id="addNewAccount" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('accounts_management.new_account') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label">{{ $t('accounts_management.username') }}</label>
              <input type="text" class="form-control" v-model="editUsername">
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t('accounts_management.first_name') }}</label>
              <input type="text" class="form-control" v-model="editFName">
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t('accounts_management.last_name') }}</label>
              <input type="text" class="form-control" v-model="editLName">
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t('accounts_management.telf') }}</label>
              <input type="text" class="form-control" v-model="editPhone">
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t('accounts_management.email') }}</label>
              <input type="text" class="form-control" v-model="editEmail">
            </div>
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" v-model="editDemo">
              <label class="form-check-label">{{ $t('accounts_management.demo_account') }}</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('accounts_management.close') }}
            </button>
            <button type="button" class="btn btn-primary" style="background-color: #0983a3" @click="createAccount"
                    :disabled="editPhone == '' || editFName == '' || editLName == '' || editUsername == '' || editEmail == ''"
            >
              {{ $t('accounts_management.create') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-success" style="margin-bottom: 1em" @click="showNewAccountDialog"
    ><i
        class="fas fa-plus"></i> {{ $t('accounts_management.new_account') }}
    </button>
    <div class="table-responsive">
      <table class="table table-sm table-hover">
        <thead style="background-color: #2596be; color: white">
        <tr>
          <th scope="col">{{ $t('accounts_management.id') }}</th>
          <th scope="col">{{ $t('accounts_management.username') }}</th>
          <th scope="col">{{ $t('accounts_management.first_name') }}</th>
          <th scope="col">{{ $t('accounts_management.last_name') }}</th>
          <th scope="col">{{ $t('accounts_management.telf') }}</th>
          <th scope="col">{{ $t('accounts_management.email') }}</th>
          <th scope="col">{{ $t('accounts_management.type') }}</th>
          <th scope="col">{{ $t('accounts_management.expiration_date') }}</th>
          <th scope="col">{{ $t('accounts_management.active') }}</th>
          <th scope="col">{{ $t('accounts_management.actions') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(acc, index) in split_logs[current_view - 1]" :key="index">
          <th>{{ acc.id }}</th>
          <th>{{ acc.username }}</th>
          <th v-if="editingAccount != acc.id">
            <span v-if="acc.first_name == ''">{{ $t('accounts_management.not_available') }}</span>
            <span v-if="acc.first_name != ''">{{ acc.first_name }}</span>
          </th>
          <th v-if="editingAccount == acc.id">
            <input class="form-control-sm" v-model="editFName">
          </th>
          <th v-if="editingAccount != acc.id">
            <span v-if="acc.last_name == ''">{{ $t('accounts_management.not_available') }}</span>
            <span v-if="acc.last_name != ''">{{ acc.last_name }}</span>
          </th>
          <th v-if="editingAccount == acc.id">
            <input class="form-control-sm" v-model="editLName">
          </th>
          <th v-if="editingAccount != acc.id">
            <span v-if="acc.tel == ''">{{ $t('accounts_management.not_available') }}</span>
            <span v-if="acc.tel != ''">{{ acc.tel }}</span>
          </th>
          <th v-if="editingAccount == acc.id">
            <input class="form-control-sm" v-model="editPhone">
          </th>
          <th v-if="editingAccount != acc.id">
            <span v-if="acc.email == ''">{{ $t('accounts_management.not_available') }}</span>
            <span v-if="acc.email != ''">{{ acc.email }}</span>
          </th>
          <th v-if="editingAccount == acc.id">
            <input class="form-control-sm" v-model="editEmail">
          </th>
          <th>
            <span v-if="acc.type == 1">{{ $t('accounts_management.admin') }}</span>
            <span v-if="acc.type == 0">{{ $t('accounts_management.tech') }}</span>
            <span v-if="acc.type == -1">{{ $t('accounts_management.machine') }}</span>
          </th>
          <th v-if="editingAccount != acc.id">{{ acc.expiration_date }}</th>
          <th v-if="editingAccount == acc.id">
            <input class="form-control-sm" v-model="editDate" type="datetime-local">
          </th>
          <th>
            <span v-if="acc.active == true">{{ $t('accounts_management.yes') }}</span>
            <span v-if="acc.active != true">{{ $t('accounts_management.no') }}</span>
          </th>
          <th>
            <span v-if="acc.type != 1">
            <button class="btn btn-sm btn-success" style="margin-left: 0.5em;" v-if="editingAccount == acc.id"
                    @click="saveAccount(acc.id)"
            ><i class="fas fa-save"
                style="color: white"></i>
            </button>
            <button class="btn btn-sm btn-warning" style="margin-left: 0.5em" v-if="editingAccount != acc.id"
                    @click="editAccount(acc)"
            ><i class="fas fa-edit"
                style="color: white"></i>
            </button>
            <button class="btn btn-sm btn-danger" style="margin-left: 0.5em;" v-if="acc.active"
                    @click="disableAccount(acc)"><i
                class="fas fa-trash"></i>
            </button>
            <button class="btn btn-sm btn-primary" style="margin-left: 0.5em;" v-if="!acc.active"
                    @click="enableAccount(acc)"><i
                class="fas fa-trash-restore"></i>
            </button>
              <button class="btn btn-sm btn-secondary" style="margin-left: 0.5em;" v-if="acc.active"
                      @click="regenPassword(acc.id)"><i
                  class="fas fa-redo-alt"></i>
            </button>
              </span>
          </th>
        </tr>
        </tbody>
      </table>
    </div>
    <nav aria-label="Page navigation example" v-if="split_logs.length != 0">
      <ul class="pagination">
        <li class="page-item disabled" v-if="current_view == 1">
          <a class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item" v-if="current_view != 1" @click="decreaseView">
          <a class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item disabled"><a class="page-link" style="background-color: #2596be; color: white"
                                          href="javascript:void(0)"><b>{{ current_view }}</b></a></li>
        <li class="page-item" v-if="current_view != split_logs.length" @click="increaseView">
          <a class="page-link" href="javascript:void(0)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
        <li class="page-item disabled" v-if="current_view == split_logs.length">
          <a class="page-link" href="javascript:void(0)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {api} from "@/main";
import axios from "axios";
import $ from 'jquery'
import * as toastr from "@/assets/toastr";

export default {
  name: "AccountsManagement",
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    lang: String
  },
  data() {
    return {
      current_view: 1,
      view_logs: [],
      split_logs: [],
      accounts: [],
      sMachine: -1,
      editFName: "",
      editLName: "",
      editPhone: "",
      editDate: "",
      editingAccount: -1,
      editUsername: "",
      editEmail: "",
      editDemo: false
    }
  },
  created() {
    this.getAccounts()
  },
  methods: {
    showNewAccountDialog() {
      this.editDate = ""
      this.editDemo = false
      this.editUsername = ""
      this.editLName = ""
      this.editFName = ""
      this.editPhone = ""
      this.editEmail = ""
      $('#addNewAccount').modal('show')
    },
    editAccount(acc) {
      this.editFName = acc.first_name
      this.editLName = acc.last_name
      this.editEmail = acc.email
      this.editDate = acc.expiration_date.slice(6, 10) + "-" + acc.expiration_date.slice(3, 5) + "-" +
          acc.expiration_date.slice(0, 2) + "T" + acc.expiration_date.slice(11, 16)
      this.editPhone = acc.tel
      this.editingAccount = acc.id
    },
    saveAccount(id) {
      this.editingAccount = -1
      this.updateAccount({
        "fname": this.editFName,
        "lname": this.editLName,
        "phone": this.editPhone,
        "email": this.editEmail,
        "expiration_date": this.editDate.slice(8, 10) + "/" + this.editDate.slice(5, 7) + "/" +
            this.editDate.slice(0, 4) + " " + this.editDate.slice(11) + ":00"
      }, id)
    },
    decreaseView() {
      this.current_view -= 1
    },
    increaseView() {
      this.current_view += 1
    },
    splitCodes(codes) {
      var i, j, temparray, chunk = 10
      for (i = 0, j = codes.length; i < j; i += chunk) {
        temparray = codes.slice(i, i + chunk)
        this.split_logs.push(temparray)
      }
      this.view_logs = this.split_logs[0]
    },
    regenPassword(id) {
      const path = api + "account/" + String(id) + "/password/regen"
      axios.post(path, {}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.getAccounts()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    disableAccount(a) {
      this.updateAccount({
        "fname": a.first_name,
        "lname": a.last_name,
        "phone": a.tel,
        "email": a.email,
        "expiration_date": a.expiration_date,
        "active": false
      }, a.id)
    },
    enableAccount(a) {
      this.updateAccount({
        "fname": a.first_name,
        "lname": a.last_name,
        "phone": a.tel,
        "email": a.email,
        "expiration_date": a.expiration_date,
        "active": true
      }, a.id)
    },
    createAccount() {
      $('#addNewAccount').modal('hide')
      const path = api + 'account'
      axios.post(path, {
        "fname": this.editFName,
        "lname": this.editLName,
        "phone": this.editPhone,
        "email": this.editEmail,
        "demo": this.editDemo,
        "username": this.editUsername
      }, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.getAccounts()
          })
          .catch((error) => {
            // eslint-disable-next-line
            toastr.error('', this.$t('accounts_management.create_account_error'),
                {
                  timeOut: 1500,
                  progressBar: true,
                  newestOnTop: true,
                  positionClass: 'toast-bottom-right',
                  preventDuplicates: true
                })
            console.error(error)
          })
    },
    updateAccount(data, id) {
      const path = api + 'account/' + id
      axios.put(path, data, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.getAccounts()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    getAccounts() {
      const path = api + 'accounts'
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.accounts = []
            this.split_logs = []
            this.view_logs = []
            this.accounts = res.data.accounts
            this.splitCodes(res.data.accounts)
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    }
  }
}
</script>

<style scoped>

</style>