<template>
  <div class="container-xl animate__animated animate__fadeInUp" style="margin-top: 1.5em; max-width: 1880px !important; margin-bottom: 0.5em">
    <div class="card">
      <div class="card-body" v-if="!empty">
        <h5>{{ $t('hardware_monitor.system_status') }}</h5>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-info text-uppercase mb-1">{{ $t('hardware_monitor.cpu_usage') }}
                      </div>
                      <div class="row no-gutters align-items-center">
                        <div class="col-auto">
                          <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ data.cpu_usage }}%</div>
                        </div>
                        <div class="col">
                          <div class="progress progress-sm mr-2">
                            <div class="progress-bar bg-info" role="progressbar"
                                 :style="'width:' + data.cpu_usage + '%'" :aria-valuenow="data.cpu_usage"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-microchip fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
                <div class="card-footer" style="background-color: transparent">
                  {{ $t('hardware_monitor.current_freq') }}: <b>{{ (data.cpu_freq / 1000).toFixed(2) }} GHz</b> {{ $t('hardware_monitor.max_freq') }}:
                  <b>{{ (data.cpu_freq_max / 1000).toFixed(2) }} GHz</b>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">{{ $t('hardware_monitor.memory_usage') }}
                      </div>
                      <div class="row no-gutters align-items-center">
                        <div class="col-auto">
                          <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ data.ram_pcent }}%</div>
                        </div>
                        <div class="col">
                          <div class="progress progress-sm mr-2">
                            <div class="progress-bar bg-primary" role="progressbar"
                                 :style="'width:' + data.ram_pcent + '%'" :aria-valuenow="data.ram_pcent"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-memory fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
                <div class="card-footer" style="background-color: transparent">
                  {{ $t('hardware_monitor.in_use') }}: <b>{{ (data.ram_usage / 1000).toFixed(2) }} GB</b> {{ $t('hardware_monitor.total') }}:
                  <b>{{ (data.total_ram / 1000).toFixed(2) }} GB</b>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">{{ $t('hardware_monitor.swap_usage') }}
                      </div>
                      <div class="row no-gutters align-items-center">
                        <div class="col-auto">
                          <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ data.swap_pcent }}%</div>
                        </div>
                        <div class="col">
                          <div class="progress progress-sm mr-2">
                            <div class="progress-bar bg-warning" role="progressbar"
                                 :style="'width:' + data.swap_pcent + '%'" :aria-valuenow="data.swap_pcent"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-retweet fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
                <div class="card-footer" style="background-color: transparent">
                  {{ $t('hardware_monitor.in_use') }}: <b>{{ (data.swap / 1000).toFixed(2) }} GB</b> {{ $t('hardware_monitor.total') }}:
                  <b>{{ (data.swap_total / 1000).toFixed(2) }} GB</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5>{{ $t('hardware_monitor.performance_charts') }}</h5>
        <div class="container-fluid">
          <div class="row row-cols-1 row-cols-md-3" style="margin-top: 1em">
            <div class="col">
              <apexchart
                  width="100%"
                  type="area"
                  :options="cpuChartOptions"
                  :series="cpuSeries"
              ></apexchart>
            </div>
            <div class="col">
              <apexchart
                  width="100%"
                  type="area"
                  :options="ramChartOptions"
                  :series="ramSeries"
              ></apexchart>
            </div>
            <div class="col">
              <apexchart
                  width="100%"
                  type="area"
                  :options="swapChartOptions"
                  :series="swapSeries"
              ></apexchart>
            </div>
          </div>
        </div>
        <h5>{{ $t('hardware_monitor.system_stats') }}<br><h6>{{ $t('hardware_monitor.since_start') }}</h6></h5>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-success shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                        {{ $t('hardware_monitor.data_written_d') }}
                      </div>
                      <div class="h5 mb-0 font-weight-bold text-gray-800">{{ data.d_total_written }} {{ $t('hardware_monitor.bytes') }}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-hdd fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-success shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                        {{ $t('hardware_monitor.data_read_d') }}
                      </div>
                      <div class="h5 mb-0 font-weight-bold text-gray-800">{{ data.d_total_read }} {{ $t('hardware_monitor.bytes') }}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-hdd fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-success shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                        {{ $t('hardware_monitor.data_sent_n') }}
                      </div>
                      <div class="h5 mb-0 font-weight-bold text-gray-800">{{ data.n_total_sent }} {{ $t('hardware_monitor.bytes') }}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-network-wired fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-success shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                        {{ $t('hardware_monitor.data_rec_n') }}
                      </div>
                      <div class="h5 mb-0 font-weight-bold text-gray-800">{{ data.n_total_received }} {{ $t('hardware_monitor.bytes') }}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-network-wired fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5>{{ $t('hardware_monitor.cpu_load_dstr') }}<br><h6>{{ data.physycal_cores }} {{ $t('hardware_monitor.physical_cores') }}, {{ data.logical_cores }} {{ $t('hardware_monitor.logical_cores') }}</h6></h5>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 col-md-6 mb-4" v-for="(core, index) in data.cpu_pcent_core" :key="index">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-info text-uppercase mb-1">{{ $t('hardware_monitor.core') }} {{ core.num }}
                      </div>
                      <div class="row no-gutters align-items-center">
                        <div class="col-auto">
                          <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ core.percent }}%</div>
                        </div>
                        <div class="col">
                          <div class="progress progress-sm mr-2">
                            <div class="progress-bar bg-info" role="progressbar"
                                 :style="'width:' + core.percent + '%'" :aria-valuenow="core.percent"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-microchip fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5>{{ $t('hardware_monitor.gpus') }}</h5>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 col-md-6 mb-4" v-for="(gpu, index) in data.gpus" :key="index">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">{{ gpu.name }}
                      </div>
                      <div class="row no-gutters align-items-center">
                        <div class="col-auto">
                          <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ gpu.load }}%</div>
                        </div>
                        <div class="col">
                          <div class="progress progress-sm mr-2">
                            <div class="progress-bar bg-warning" role="progressbar"
                                 :style="'width:' + gpu.load + '%'" :aria-valuenow="gpu.load"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-bolt fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
                <div class="card-footer" style="background-color: transparent">
                  {{ $t('hardware_monitor.used_mem') }}: <b>{{ (gpu.used_memory / 1000).toFixed(2) }} GB</b> {{ $t('hardware_monitor.total_mem') }}:
                  <b>{{ (gpu.total_memory / 1000).toFixed(2) }} GB</b>
                  {{ $t('hardware_monitor.temperature') }}: <b>{{ gpu.temperature }}ºC</b><br> {{ $t('hardware_monitor.uuid') }}: <b>{{ gpu.uuid }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5>{{ $t('hardware_monitor.disks_drives') }}</h5>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 col-md-6 mb-4" v-for="(disk, index) in data.disks" :key="index">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-success text-uppercase mb-1">{{ $t('hardware_monitor.disk') }} {{ disk.mountpoint }}
                        ({{ disk.partition_type }})
                      </div>
                      <div class="row no-gutters align-items-center">
                        <div class="col-auto">
                          <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ (disk.used / 1000).toFixed(2) }}
                            GB
                          </div>
                        </div>
                        <div class="col">
                          <div class="progress progress-sm mr-2">
                            <div class="progress-bar bg-success" role="progressbar"
                                 :style="'width:' + disk.pcent + '%'" :aria-valuenow="disk.pcent"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ (disk.total / 1000).toFixed(2) }}
                            GB
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="text-align: center; margin-top: 1.5em" class="animate__animated animate__zoomIn">
          <div class="spinner-border text-info" role="status" style="height: 8em; width: 8em">
            <span><img src="https://leroderic.github.io/squid_remote/media/favicon.png" style="width: 7em"></span>
          </div>
          <h3 style="margin-top: 1em">{{ $t('dashboard.waiting_data') }}</h3>
        </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import * as toastr from "@/assets/toastr";
import {api} from "@/main";
import axios from "axios";

export default {
  name: "HardwareMonitoring",
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    machine_id: Number
  },
  data() {
    return {
      empty: true,
      cpuChartOptions: {
        colors: ["#17a2b8"],
        chart: {
          id: "vuechart-example",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        tooltip: {
          enabled: false
        },
        title: {
          text: this.$t('hardware_monitor.cpu_usage'),
          align: 'center',
          margin: 10,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#263238'
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [],
        },
        yaxis: {
          max: 100
        }
      },
      ramChartOptions: {
        colors: ["#007bff"],
        chart: {
          id: "vuechart-example",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        tooltip: {
          enabled: false
        },
        title: {
          text: this.$t('hardware_monitor.memory_usage'),
          align: 'center',
          margin: 10,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#263238'
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [],
        }
      },
      swapChartOptions: {
        colors: ["#ffc107"],
        chart: {
          id: "vuechart-example",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        tooltip: {
          enabled: false
        },
        title: {
          text: this.$t('hardware_monitor.swap_usage'),
          align: 'center',
          margin: 10,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#263238'
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [],
        }
      },
      cpuSeries: [
        {
          data: []
        }
      ],
      ramSeries: [
        {
          data: [],
        }
      ],
      swapSeries: [
        {
          data: [],
        }
      ],
      data: {},
      machine: {},
      gpuArraysUsageOptions: [],
      gpuArraysTempOptions: [],
      gpuArraysUsageSeries: [],
      gpuArraysTempSeries: []
    }
  }, methods: {
    updateCharts() {
      this.cpuChartOptions.xaxis.categories.push(this.data.time)
      this.ramChartOptions.xaxis.categories.push(this.data.time)
      this.swapChartOptions.xaxis.categories.push(this.data.time)
      this.cpuSeries[0].data.push(this.data.cpu_usage)
      this.ramSeries[0].data.push(this.data.ram_usage)
      this.swapSeries[0].data.push(this.data.swap)

      this.cpuChartOptions.xaxis.categories = this.cpuChartOptions.xaxis.categories.slice(this.cpuChartOptions.xaxis.categories.length - 20, 20)
      this.ramChartOptions.xaxis.categories = this.ramChartOptions.xaxis.categories.slice(this.ramChartOptions.xaxis.categories.length - 20, 20)
      this.swapChartOptions.xaxis.categories = this.swapChartOptions.xaxis.categories.slice(this.swapChartOptions.xaxis.categories.length - 20, 20)
      this.cpuSeries[0].data = this.cpuSeries[0].data.slice(this.cpuSeries[0].data.length - 20, 20)
      this.ramSeries[0].data = this.ramSeries[0].data.slice(this.ramSeries[0].data.length - 20, 20)
      this.swapSeries[0].data = this.swapSeries[0].data.slice(this.swapSeries[0].data.length - 20, 20)
    },
    changeOpCode(m, n, opcode) {
      const path = api + 'machine/' + m.mac_address + '/' + n
      axios.post(path, {"opcode": opcode, "account_id": this.id}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    getMachineTriggerInit(id) {
      const path = api + 'machine/' + id
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.machine = res.data.machine
            this.subscribeBindChannels()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    getData() {
      const path = api + 'machine/' + this.machine.mac_address + '/hardware_monitor'
      axios.get(path, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.empty = false
            this.data = res.data
            this.updateCharts()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    hardwareMonitoringChannel() {
      var channel = this.pusher.subscribe('private-' + this.machine.mac_address.replace(/:/g, ""))
      // eslint-disable-next-line no-unused-vars
      channel.bind("sys_stats_update", this.getData)
    },
    subscribeBindChannels() {
      this.hardwareMonitoringChannel()
    }
  },
  created() {
    scrollTo(0, 0)
    this.getMachineTriggerInit(this.machine_id)
  },
  mounted() {
  }
}
</script>

<style scoped>

ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

li {
  padding-left: 1em;
  text-indent: -1em;
}

li:before {
  content: "»";
  padding-right: 5px;
}

th {
  position: sticky;
}

tbody tr:nth-child(odd) {
  background-color: #3f5259;
  color: #fff;
}

tbody tr:nth-child(even) {
  background-color: #2e373b;
  color: #fff;
}

::-webkit-scrollbar {
  height: 0.5em !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c81a1;
  border-radius: 10px;
}

.border-left-primary {
  border-left: .25rem solid #4e73df !important;
}

.shadow {
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
}

</style>