<template>
  <div>
    <div class="table-responsive">
      <table class="table table-sm table-hover">
        <thead style="background-color: #2596be; color: white">
        <tr>
          <th scope="col">{{ $t('login_logs_view.id') }}</th>
          <th scope="col">{{ $t('login_logs_view.username') }}</th>
          <th scope="col">{{ $t('login_logs_view.date') }}</th>
          <th scope="col">{{ $t('login_logs_view.extension_login') }}</th>
          <th scope="col">{{ $t('login_logs_view.debug_auth') }}</th>
          <th scope="col">{{ $t('login_logs_view.agent_type') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(log, index) in split_logs[current_view - 1]" :key="index">
          <th>{{ log.id }}</th>
          <th>{{ log.account_username }}</th>
          <th>{{ log.date }}</th>
          <th>
            <span v-if="log.extension_login == true">{{ $t('login_logs_view.yes') }}</span>
            <span v-if="log.extension_login == false">{{ $t('login_logs_view.no') }}</span>
          </th>
          <th>
            <span v-if="log.debug_auth == true">{{ $t('login_logs_view.yes') }}</span>
            <span v-if="log.debug_auth == false">{{ $t('login_logs_view.no') }}</span>
          </th>
          <th v-if="log.agent_type == 0">{{ $t('login_logs_view.zero') }}</th>
          <th v-if="log.agent_type == 1">{{ $t('login_logs_view.one') }}</th>
          <th v-if="log.agent_type == 2">{{ $t('login_logs_view.two') }}</th>

        </tr>
        </tbody>
      </table>
    </div>
    <nav aria-label="Page navigation example" v-if="split_logs.length != 0">
      <ul class="pagination">
        <li class="page-item disabled" v-if="current_view == 1">
          <a class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item" v-if="current_view != 1" @click="decreaseView">
          <a class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item disabled"><a class="page-link" style="background-color: #2596be; color: white"
                                          href="javascript:void(0)"><b>{{ current_view }}</b></a></li>
        <li class="page-item" v-if="current_view != split_logs.length" @click="increaseView">
          <a class="page-link" href="javascript:void(0)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
        <li class="page-item disabled" v-if="current_view == split_logs.length">
          <a class="page-link" href="javascript:void(0)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {api} from "@/main";
import axios from "axios";

export default {
  name: "LoginLogsView",
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    lang: String
  },
  data() {
    return {
      current_view: 1,
      view_logs: [],
      split_logs: []
    }
  },
  created() {
    this.getCodes()
  },
  methods: {
    decreaseView() {
      this.current_view -= 1
    },
    increaseView() {
      this.current_view += 1
    },
    getCodes() {
      this.split_logs = []
      const path = api + 'login/logs'
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.splitCodes(res.data.login_logs)
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    splitCodes(codes) {
      var i, j, temparray, chunk = 10
      for (i = 0, j = codes.length; i < j; i += chunk) {
        temparray = codes.slice(i, i + chunk)
        this.split_logs.push(temparray)
      }
      this.view_logs = this.split_logs[0]
    }
  }
}
</script>

<style scoped>

</style>