<style>
@import url("../assets/animate.min.css");
@import url("../assets/toastr.css");
@import url("../assets/flags.css");
</style>
<template>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css"
        integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css">
  <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet' type='text/css'>
  <div>
    <div style="background-color: #191c1e">
      <div class="container" style="max-width: 1880px">
        <nav class="navbar navbar-expand-md navbar-dark bg-dark"
             style="background-color: #191c1e !important;" id="dashboard-nav">
        <span class="navbar-nav mr-auto">
            <img class="navbar-brand animate__animated animate__flip"
                 src="https://leroderic.github.io/squid_remote/media/favicon.png"
                 style="height: 45px; margin: 0">
          </span>
          <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
          </div>
          <div class="mx-auto order-0">
            <a class="navbar-brand ml-auto" href="javascript:void(0)">{{ $t('admin.title') }}</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2"
                    style="margin-right: -1.5em">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" style="text-align: center" href="javascript:void(0)" data-toggle="collapse"
                   data-target=".dual-collapse2"
                   @click="help = !help" v-if="!help">{{ $t('main.help') }}</a>
                <a class="nav-link" style="text-align: center" href="javascript:void(0)" data-toggle="collapse"
                   data-target=".dual-collapse2"
                   @click="help = !help" v-if="help">{{ $t('main.chelp') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)"
                   style="text-align: center" @click="back2Main">{{ $t('admin.back_to_main') }}</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="javascript:void(0)"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-align: center">
                  <span class="flag flag-es" v-if="lang == 'ES'"></span>
                  <span class="flag flag-gb" v-if="lang == 'EN'"></span>
                  <span class="flag flag-catalonia" v-if="lang == 'CA'"></span>
                </a>
                <div class="dropdown-menu" style="min-width: inherit"
                     aria-labelledby="dropdown09">
                  <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'EN'"
                     @click="updateLang('EN')" data-toggle="collapse" data-target=".dual-collapse2"><span
                      class="flag flag-gb" style="text-align: center"></span></a>
                  <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'ES'"
                     @click="updateLang('ES')" data-toggle="collapse" data-target=".dual-collapse2"><span
                      class="flag flag-es" style="text-align: center"></span></a>
                  <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'CA'"
                     @click="updateLang('CA')" data-toggle="collapse" data-target=".dual-collapse2"><span
                      class="flag flag-catalonia" style="text-align: center"></span></a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <div class="container" style="max-width: 1880px" v-show="!help">
      <div id="accordion" style="margin-top: 1em">
        <div class="card">
          <div class="card-header" id="dCodesHeader">
            <h5 class="mb-0">
              <button class="btn btn-link" data-toggle="collapse" data-target="#dCodes" aria-expanded="false"
                      aria-controls="collapseDCodes">
                {{ $t('admin.download_codes') }}
              </button>
            </h5>
          </div>

          <div id="dCodes" class="collapse show" aria-labelledby="dCodes" data-parent="#accordion">
            <div class="card-body">
              <download-codes-management :id="id" :type="type" :token="token" :pusher="pusher"></download-codes-management>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="loginLogsHeader">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseLoginLogs"
                      aria-expanded="false" aria-controls="collapseTwo">
                {{ $t('admin.login_logs') }}
              </button>
            </h5>
          </div>
          <div id="collapseLoginLogs" class="collapse" data-parent="#accordion">
            <div class="card-body">
              <LoginLogsView :id="id" :type="type" :token="token" :pusher="pusher"></LoginLogsView>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="machineLogsHeader">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseMachineLogs"
                      aria-expanded="false" aria-controls="collapseTwo">
                {{ $t('admin.machine_logs') }}
              </button>
            </h5>
          </div>
          <div id="collapseMachineLogs" class="collapse" data-parent="#accordion">
            <div class="card-body">
              <MachineLogsView :id="id" :type="type" :token="token" :pusher="pusher"></MachineLogsView>
            </div>
          </div>
        </div>
        <div class="card" v-if="type == 1">
          <div class="card-header" id="accountManagerHeader">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseAccountManager"
                      aria-expanded="false" aria-controls="collapseTwo">
                {{ $t('admin.account_management') }}
              </button>
            </h5>
          </div>
          <div id="collapseAccountManager" class="collapse" data-parent="#accordion">
            <div class="card-body">
              <AccountsManagement :id="id" :type="type" :token="token" :pusher="pusher"></AccountsManagement>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="aboutSection">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseAboutSection"
                      aria-expanded="false" aria-controls="collapseTwo">
                {{ $t('admin.about') }}
              </button>
            </h5>
          </div>
          <div id="collapseAboutSection" class="collapse" data-parent="#accordion">
            <div class="card-body">
              <AboutSquidRemote></AboutSquidRemote>
            </div>
          </div>
        </div>
      </div>
      <footer style="text-align: center">
        {{ $t('admin.copyright') }} {{ getYear() }} <span style="font-family: 'logo_font'">squid</span>
      </footer>
    </div>
    <admin-help v-show="help"></admin-help>
  </div>
</template>

<script>
import {bus, api} from "@/main";
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import * as toastr from "@/assets/toastr";
// eslint-disable-next-line no-unused-vars
import $ from 'jquery'
import DownloadCodesManagement from "@/components/DownloadCodesManagement";
import LoginLogsView from "@/components/LoginLogsView";
import MachineLogsView from "@/components/MachineLogsView";
import AccountsManagement from "@/components/AccountsManagement";
import AboutSquidRemote from "./AboutSquidRemote";
import AdminHelp from "./AdminHelp";

export default {
  name: "Main",
  components: {AdminHelp, AboutSquidRemote, AccountsManagement, MachineLogsView, LoginLogsView, DownloadCodesManagement},
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    lang: String
  },
  data() {
    return {
      magic: false,
      machines: [],
      help: false
    }
  },
  created() {
    scrollTo(0, 0)
  },
  mounted() {
    this.getMachines()
  },
  methods: {
    updateLang(l) {
      // eslint-disable-next-line vue/no-mutating-props
      bus.emit('lang-updated', {
        'lang': l,
      })
    },
    back2Main() {
      this.$router.push('/')
    },
    logout() {
      if (this.$cookie.isCookieAvailable("srld")) {
        this.$cookie.removeCookie("srld")
      }
      this.$router.push('/')
    },
    getMachines() {
      const path = api + 'machines'
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.machines = res.data.machines
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    getYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
@font-face {
  src: url("../assets/namo.ttf");
  font-family: "logo_font";
}

footer {
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 450px) {
  footer {
    position: relative;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0.5em;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c81a1;
  border-radius: 10px;
}
</style>