<template>
  <div>
    <select class="form-control" v-model="sMachine" style="margin-bottom: 1em" v-on:change="getLogs">
      <option disabled value=-1>{{ $t('machine_logs_view.select_machine') }}</option>
      <option v-for="(machine, index) in machines" :key="index" :value=index>#{{ machine.id }} -
        {{ machine.mac_address }}
      </option>
    </select>
    <div class="table-responsive" v-if="sMachine != -1">
      <table class="table table-sm table-hover">
        <thead style="background-color: #2596be; color: white">
        <tr>
          <th scope="col">{{ $t('machine_logs_view.id') }}</th>
          <th scope="col">{{ $t('machine_logs_view.username') }}</th>
          <th scope="col">{{ $t('machine_logs_view.operation') }}</th>
          <th scope="col">{{ $t('machine_logs_view.date') }}</th>
          <th scope="col">{{ $t('machine_logs_view.previous_value') }}</th>
          <th scope="col">{{ $t('machine_logs_view.new_value') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(log, index) in split_logs[current_view - 1]" :key="index">
          <th>{{ log.id }}</th>
          <th>{{ log.account_username }}</th>
          <th>{{ log.operation }}</th>
          <th>{{ log.date }}</th>
          <th>{{ log.previous_value }}</th>
          <th>{{ log.new_value }}</th>
        </tr>
        </tbody>
      </table>
    </div>
    <nav aria-label="Page navigation example" v-if="sMachine != -1 && split_logs.length != 0">
      <ul class="pagination">
        <li class="page-item disabled" v-if="current_view == 1">
          <a class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item" v-if="current_view != 1" @click="decreaseView">
          <a class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item disabled"><a class="page-link" style="background-color: #2596be; color: white"
                                          href="javascript:void(0)"><b>{{ current_view }}</b></a></li>
        <li class="page-item" v-if="current_view != split_logs.length" @click="increaseView">
          <a class="page-link" href="javascript:void(0)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
        <li class="page-item disabled" v-if="current_view == split_logs.length">
          <a class="page-link" href="javascript:void(0)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {api} from "@/main";
import axios from "axios";

export default {
  name: "MachineLogsView",
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    lang: String
  },
  data() {
    return {
      current_view: 1,
      view_logs: [],
      split_logs: [],
      machines: [],
      sMachine: -1
    }
  },
  created() {
    this.getMachinesHere()
  },
  methods: {
    decreaseView() {
      this.current_view -= 1
    },
    increaseView() {
      this.current_view += 1
    },
    getLogs() {
      this.split_logs = []
      const path = api + 'machine/log/' + this.machines[this.sMachine].mac_address
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.splitCodes(res.data.machine_logs)
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    splitCodes(codes) {
      var i, j, temparray, chunk = 10
      for (i = 0, j = codes.length; i < j; i += chunk) {
        temparray = codes.slice(i, i + chunk)
        this.split_logs.push(temparray)
      }
      this.view_logs = this.split_logs[0]
    },
    getMachinesHere() {
      const path = api + 'machines'
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.machines = res.data.machines
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    }
  }
}
</script>

<style scoped>

</style>