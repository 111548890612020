<style>
@import url("../assets/animate.min.css");
@import url("../assets/toastr.css");
@import url("../assets/flags.css");
</style>
<template>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css"
        integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css">
  <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet' type='text/css'>
  <div>
    <div style="background-color: #191c1e">
      <div class="container" style="max-width: 1880px">
        <nav class="navbar navbar-expand-md navbar-dark bg-dark"
             style="background-color: #191c1e !important;" id="dashboard-nav">
        <span class="navbar-nav mr-auto">
            <img class="navbar-brand animate__animated animate__flip"
                 src="https://leroderic.github.io/squid_remote/media/favicon.png"
                 style="height: 45px; margin: 0">
          </span>
          <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
          </div>
          <div class="mx-auto order-0">
            <a class="navbar-brand ml-auto" href="javascript:void(0)">{{ $t('main.registered_machines') }}</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2"
                    style="margin-right: -1.5em">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item" data-toggle="collapse" data-target=".dual-collapse2">
                <a class="nav-link" href="javascript:void(0)"
                   style="text-align: center" @click="showDialogSendDownloadLink">{{ $t('main.send_dl_link') }}</a>
              </li>
              <li class="nav-item" data-toggle="collapse" data-target=".dual-collapse2">
                <a class="nav-link" href="javascript:void(0)" @click="getMachines"
                   style="text-align: center">{{ $t('main.refresh') }}</a>
              </li>
              <li class="nav-item" @click="$router.push('/admin')">
                <a class="nav-link" href="javascript:void(0)"
                   style="text-align: center">{{ $t('main.administration') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" style="text-align: center" href="javascript:void(0)" data-toggle="collapse"
                   data-target=".dual-collapse2"
                   @click="help = !help" v-if="!help">{{ $t('main.help') }}</a>
                <a class="nav-link" style="text-align: center" href="javascript:void(0)" data-toggle="collapse"
                   data-target=".dual-collapse2"
                   @click="help = !help" v-if="help">{{ $t('main.chelp') }}</a>
              </li>
              <li class="nav-item" data-toggle="collapse" data-target=".dual-collapse2">
                <a class="nav-link" style="text-align: center" href="javascript:void(0)"
                   @click="logout">{{ $t('main.log_out') }}</a>
              </li>
              <li class="nav-item dropdown" data-toggle="collapse" data-target=".dual-collapse2">
                <a class="nav-link dropdown-toggle" href="javascript:void(0)"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-align: center">
                  <span class="flag flag-es" v-if="lang == 'ES'"></span>
                  <span class="flag flag-gb" v-if="lang == 'EN'"></span>
                  <span class="flag flag-catalonia" v-if="lang == 'CA'"></span>
                </a>
                <div class="dropdown-menu" style="min-width: inherit"
                     aria-labelledby="dropdown09">
                  <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'EN'"
                     @click="updateLang('EN')" data-toggle="collapse" data-target=".dual-collapse2"><span
                      class="flag flag-gb" style="text-align: center"></span></a>
                  <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'ES'"
                     @click="updateLang('ES')" data-toggle="collapse" data-target=".dual-collapse2"><span
                      class="flag flag-es" style="text-align: center"></span></a>
                  <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'CA'"
                     @click="updateLang('CA')" data-toggle="collapse" data-target=".dual-collapse2"><span
                      class="flag flag-catalonia" style="text-align: center"></span></a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <div class="modal fade" id="sendDownloadLink" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('main.send_dl_link') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label">{{ $t('main.email') }}</label>
              <input type="email" class="form-control" id="dLinkEmail" placeholder="a@a.com" v-model="dEmail">
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t('main.language') }}</label>
              <select class="form-control" id="dLinkLang" required v-model="dLang">
                <option selected disabled value="">{{ $t('main.select') }}</option>
                <option value="CAS">{{ $t('main.spanish') }}</option>
                <option value="ENG">{{ $t('main.english') }}</option>
                <option value="CAT">{{ $t('main.catalan') }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t('main.platform') }}</label>
              <select class="form-control" id="dLinkPlatform" required v-model="dPlat">
                <option selected disabled value="">{{ $t('main.select') }}</option>
                <option value="WIN">{{ $t('main.windows') }}</option>
                <option value="LIN">{{ $t('main.linux') }}</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('main.close') }}</button>
            <button type="button" class="btn btn-primary" style="background-color: #0983a3"
                    :disabled="checkEmptyFieldsDownloadDialog()" @click="sendEmail">
              {{ $t('main.send') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="max-width: 1880px" v-show="!help">
      <div class="row row-cols-1 row-cols-md-2" style="margin-top: 2em">
        <div class="col mb-4 align-self-stretch" v-for="(machine, index) in machines" :key="index">
          <div class="modal fade" :id="'sendMessageMachine' + machine.id" tabindex="-1" role="dialog"
               aria-labelledby="exampleModalLabel"
               aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{{ $t('main.send_msg_machine') }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <textarea class="form-control" rows="4" maxlength="500" :id="'msgText' + machine.id"></textarea>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('main.close') }}</button>
                  <button type="button" class="btn btn-primary" style="background-color: #0983a3"
                          @click="sendMessageMachine(machine)">
                    {{ $t('main.send') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" style="background-color: #0c81a1; color: white">
              <div class="row">
                <div class="col">
                  <h5>#{{ machine.id }} - {{ machine.mac_address }}</h5>
                </div>
                <div class="col" style="text-align: right;">
                  <button class="btn btn-sm btn-success" style="margin-left: 0.5em;" v-if="edit_machine == index"
                          @click="saveMachineInfo(machine)"><i class="fas fa-save"
                                                               style="color: white"></i>
                  </button>
                  <button class="btn btn-sm btn-warning" style="margin-left: 0.5em" v-if="edit_machine != index"
                          @click="editMachine(index)"><i class="fas fa-edit"
                                                         style="color: white"></i>
                  </button>
                  <button class="btn btn-sm btn-danger" style="margin-left: 0.5em;" @click="deleteMachine(machine)"><i
                      class="fas fa-trash"></i>
                  </button>
                  <button class="btn btn-sm no-click" style="margin-left: 0.5em;">
                    <i class="fas fa-signal animate__animated animate__flash" style="color: greenyellow"
                       v-if="machine.status == 0"></i>
                    <i class="fas fa-times animate__animated animate__flash" style="color: red"
                       v-if="machine.status == -1"></i>
                    <div class="spinner-border text-warning" role="status" style="height: 1em; width: 1em"
                         v-if="machine.status == 1">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row row-cols-1 row-cols-md-3">
                <div class="col">
                  <h5>{{ $t('main.system') }}</h5>
                  <h6><span style="color: #0983a3">{{ $t('main.hostname') }}:</span> {{ machine['hostname'] }}</h6>
                  <h6><span style="color: #0983a3">{{ $t('main.architecture') }}:</span> {{ machine['architecture'] }}
                  </h6>
                  <h6><span style="color: #0983a3">{{ $t('main.processor') }}:</span> {{ machine['processor'] }}</h6>
                  <h6><span style="color: #0983a3">{{ $t('main.ram_memory') }}:</span> {{ machine.ram }}</h6>
                </div>
                <div class="col">
                  <h5>{{ $t('main.op_system') }}</h5>
                  <h6><span style="color: #0983a3">{{ $t('main.platform') }}:</span> {{ machine['platform'] }}</h6>
                  <h6><span style="color: #0983a3">{{ $t('main.platform_release') }}:</span>
                    {{ machine['platform_release'] }}</h6>
                  <h6><span style="color: #0983a3">{{ $t('main.platform_version') }}:</span>
                    {{ machine['platform_version'] }}</h6>
                </div>
                <div class="col">
                  <h5>{{ $t('main.network_cards') }}</h5>
                  <div style="max-height: 8.5em; overflow-y: auto">
                    <div v-for="card in machine.network_cards" :key="card">
                      <h6 style="color: #0983a3">{{ card.card_name }}</h6>
                      <h7>{{ card.ip_v4 }}<br></h7>
                      <h7>{{ card.ip_v6 }}</h7>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row row-cols-1 row-cols-md-2">
                <div class="col" v-if="edit_machine != index">
                  <h5>{{ $t('main.reported_incident') }}</h5>
                  <h6><span style="color: #0983a3">{{ $t('main.incident') }}:</span> {{ machine.incident }}</h6>
                  <h6><span style="color: #0983a3">{{ $t('main.date_time') }}:</span> {{ machine.incident_date }}</h6>
                  <h6><span style="color: #0983a3">{{ $t('main.more_info') }}:</span> {{ machine.incident_info }}</h6>
                </div>
                <div class="col" v-if="edit_machine == index">
                  <h5>{{ $t('main.reported_incident') }}</h5>
                  <h6><span style="color: #0983a3">{{ $t('main.incident') }}:</span> <input class="form-control"
                                                                                            v-model="machine.incident">
                  </h6>
                  <h6><span style="color: #0983a3">{{ $t('main.date_time') }}:</span> <input class="form-control"
                                                                                             v-model="machine.incident_date">
                  </h6>
                  <h6><span style="color: #0983a3">{{ $t('main.more_info') }}:</span> <input class="form-control"
                                                                                             v-model="machine.incident_info">
                  </h6>
                </div>
                <div class="col" v-if="edit_machine != index">
                  <h5>{{ $t('main.contact') }}</h5>
                  <h6><span style="color: #0983a3">{{ $t('main.name_lname') }}:</span> {{ machine.contact }}</h6>
                  <h6><span style="color: #0983a3">{{ $t('main.phone_number') }}:</span> {{ machine.contact_tel }}</h6>
                  <h6><span style="color: #0983a3">{{ $t('main.email') }}:</span> <a
                      :href="'mailto:' + machine.contact_email">{{ machine.contact_email }}</a></h6>
                  <h6><span style="color: #0983a3">{{ $t('main.more_info') }}:</span> {{ machine.contact_info }}</h6>
                </div>
                <div class="col" v-if="edit_machine == index">
                  <h5>{{ $t('main.contact') }}</h5>
                  <h6><span style="color: #0983a3">{{ $t('main.name_lname') }}:</span> <input class="form-control"
                                                                                              v-model="machine.contact">
                  </h6>
                  <h6><span style="color: #0983a3">{{ $t('main.phone_number') }}:</span> <input class="form-control"
                                                                                                v-model="machine.contact_tel"
                                                                                                type="number">
                  </h6>
                  <h6><span style="color: #0983a3">{{ $t('main.email') }}:</span><input class="form-control"
                                                                                        v-model="machine.contact_email">
                  </h6>
                  <h6><span style="color: #0983a3">{{ $t('main.more_info') }}:</span> <input class="form-control"
                                                                                             v-model="machine.contact_info">
                  </h6>
                </div>
              </div>
              <div class="row row-cols-1 row-cols-md-1" style="margin-bottom: 0.5em">
                <div class="col" v-if="edit_machine != index">
                  <h5>{{ $t('main.additional_notes') }}</h5>
                  <textarea class="form-control" rows="3" :value="machine.additional_info" disabled></textarea>
                </div>
                <div class="col" v-if="edit_machine == index">
                  <h5>{{ $t('main.additional_notes') }}</h5>
                  <textarea class="form-control" rows="3" v-model="machine.additional_info"></textarea>
                </div>

              </div>
              <div class="row row-cols-1 row-cols-md-1">
                <div class="col" style="text-align: right">
                  <button type="button" class="btn btn-outline-primary"
                          style="margin-right: 0.5em; margin-top: 0.5em"
                          v-if="machine_info[machine.id]['is_connected']" @click="goToMachine(machine)">
                    {{ $t('main.connect') }}
                  </button>
                  <button type="button" class="btn btn-outline-secondary"
                          style="margin-right: 0.5em; margin-top: 0.5em"
                          @click="startPingOnMachine(machine)"><span
                      v-if="!machine_info[machine.id]['pinging']">{{ $t('main.verify_con') }}</span>
                    <span v-if="machine_info[machine.id]['pinging']">
                      <div class="spinner-grow spinner-grow-sm text-secondary"
                           role="status">
                      <span class="sr-only">Loading...</span>
                    </div></span>
                  </button>
                  <button type="button" class="btn btn-outline-warning"
                          style="margin-right: 0.5em; margin-top: 0.5em"
                          v-if="machine_info[machine.id]['is_connected']" @click="showDialogMessage(machine)">
                    {{ $t('main.send_msg') }}
                  </button>
                  <button type="button" class="btn btn-danger" style="margin-top: 0.5em"
                          v-if="machine_info[machine.id]['is_connected']" @click="disconnectRemoteMachine(machine)">
                    {{ $t('main.disconnect') }}
                  </button>
                </div>
              </div>

            </div>
            <div class="progress" style="margin-top: 0.5em; height: 0.5em;visibility: hidden"
                 :id="'pingProgressDiv' + machine.id">
              <div class="progress-bar" role="progressbar" :id="'pingProgressBar' + machine.id"
                   style="background-color: #0983a3; width: 0%"
                   aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <help-main v-show="help"></help-main>
    <footer style="text-align: center">
      {{ $t('main.copyright') }} {{ getYear() }} <span style="font-family: 'logo_font'">squid</span>
    </footer>
  </div>
</template>

<script>
import {bus, api} from "@/main";
import axios from "axios";
import * as toastr from "@/assets/toastr";
// eslint-disable-next-line no-unused-vars
import $ from 'jquery'
import HelpMain from "./HelpMain";

export default {
  name: "Main",
  components: {HelpMain},
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    lang: String
  },
  data() {
    return {
      magic: false,
      machines: [],
      machine_info: {},
      edit_machine: -1,
      acode: 0,
      dEmail: "",
      dPlat: "",
      dLang: "",
      help: false
    }
  },
  created() {
    scrollTo(0, 0)
    if (this.$cookie.isCookieAvailable("srld")) {
      var data = this.$cookie.getCookie("srld")
      bus.emit('has-logged-in', {
        'token': data.token,
        'type': data.type,
        'id': data.id,
      })
    }
  },
  mounted() {
    this.getMachines()
    var channel = this.pusher.subscribe('private-machineStatusChange')
    channel.bind("status_update", this.getMachines)
  },
  methods: {
    updateLang(l) {
      // eslint-disable-next-line vue/no-mutating-props
      bus.emit('lang-updated', {
        'lang': l,
      })
    },
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.dEmail.toLowerCase()))
    },
    sendEmail() {
      if (!this.validateEmail()) {
        toastr.error('', this.$t('main.invalid_email'),
            {
              timeOut: 1500,
              progressBar: true,
              newestOnTop: true,
              positionClass: 'toast-bottom-right',
              preventDuplicates: true
            })
      } else {
        const path = api.slice(0, -4) + 'download'
        $("#sendDownloadLink").modal('hide')
        const data = {"email": this.dEmail, "language": this.dLang, "platform": this.dPlat}
        axios.post(path, data, {
          auth: {
            username: this.id,
            password: this.token
          }
        })
            // eslint-disable-next-line no-unused-vars
            .then((res) => {
              this.dPlat = ""
              this.dLang = ""
              this.dEmail = ""
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error) => {
              // eslint-disable-next-line
              console.error(error)
              toastr.error('', this.$t('main.send_dl_link_failed'),
                  {
                    timeOut: 1500,
                    progressBar: true,
                    newestOnTop: true,
                    positionClass: 'toast-bottom-right',
                    preventDuplicates: true
                  })
            })
      }
    },
    checkEmptyFieldsDownloadDialog() {
      if (this.dLang == "" || this.dEmail == "" || this.dPlat == "") {
        return true
      }
      return false
    },
    showDialogSendDownloadLink() {
      $("#sendDownloadLink").modal('show')
    },
    showDialogMessage(m) {
      $("#sendMessageMachine" + m.id).modal('show')
    },
    // eslint-disable-next-line no-unused-vars
    setOfflineMachineStatus(m) {
      const path = api + 'machine/' + m.mac_address + '/status'
      axios.post(path, {"status": -1}, {
        auth: {
          username: this.id,
          password: this.token
        }
      })
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.getMachines()
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    sendMessageMachine(m) {
      const path = api + 'machine/' + m.mac_address + '/message'
      axios.post(path, {"message": document.getElementById("msgText" + m.id).value}, {
        auth: {
          username: this.id,
          password: this.token
        }
      })
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            $("#sendMessageMachine" + m.id).modal('hide')
            document.getElementById("msgText" + m.id).value = ""
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    disconnectRemoteMachine(m) {
      this.changeOpCode(m, 2, -1)
      this.machine_info[m.id]["is_connected"] = false
    },
    initMachineMetaData() {
      this.machine_info = {}
      // eslint-disable-next-line no-unused-vars
      for (var i of this.machines) {
        var connected = i.status == 0
        this.machine_info["" + i.id] = {"is_connected": connected, "pinging": false}
      }
    },
    pingTestMachineNoResponse(m) {
      this.setOfflineMachineStatus(m)
      toastr.error('', this.$t('main.verify_con_failed'),
          {
            timeOut: 1500,
            progressBar: true,
            newestOnTop: true,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
          })
      this.machine_info[m.id]["pinging"] = false
      document.getElementById("pingProgressDiv" + m.id).style.visibility = "hidden"
    },
    goToMachine(m) {
      this.$router.push('/machine/' + m.id)
    },
    startPingOnMachine(m) {
      if (this.machine_info[m.id]['pinging']) {
        return ""
      }
      document.getElementById("pingProgressDiv" + m.id).style.visibility = "visible"
      this.changeOpCode(m, 0, 'PNG-5')
      this.machine_info[m.id]["pinging"] = true
      var channel = this.pusher.subscribe('private-' + m.mac_address.replace(/:/g, ""))
      channel.unbind("ping_test")
      var errorTimeOut = setTimeout(() => {
        this.pingTestMachineNoResponse(m)
      }, 10000)
      channel.bind("ping_test", function (data) {
        var current = data["current_ping"]
        current = parseInt(current.slice(-1))

        if (current == 4) {
          this.w.clearTimeout(this.error_to)
        }

        this.progress.style.width = String(100 - 20 * current) + "%"

        if (current == 0) {
          this.mmdata[m.id]["pinging"] = false
          this.mmdata[m.id]["is_connected"] = true
          this.toastr.success('', this.trans('main.verify_con_success'),
              {
                timeOut: 1500,
                progressBar: true,
                newestOnTop: true,
                positionClass: 'toast-bottom-right',
                preventDuplicates: true
              })
          this.progress.style.width = "0%"
          this.progress_div.style.visibility = "hidden"
        }
      }, {
        machine: m,
        channel: channel,
        progress: document.getElementById("pingProgressBar" + m.id),
        toastr: toastr,
        progress_div: document.getElementById("pingProgressDiv" + m.id),
        error_to: errorTimeOut,
        w: window,
        mmdata: this.machine_info,
        trans: this.$t
      })
    },
    logout() {
      if (this.$cookie.isCookieAvailable("srld")) {
        this.$cookie.removeCookie("srld")
      }
      this.$router.push('/')
    },
    getMachineOpcode(m, n) {
      const path = api + 'machine/' + m.mac_address + '/' + n
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            return res.data.opcode
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    changeOpCode(m, n, opcode) {
      const path = api + 'machine/' + m.mac_address + '/' + n
      axios.post(path, {"opcode": opcode, "account_id": this.id}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    deleteMachine(m) {
      const path = api + 'machine/' + m.id
      axios.delete(path, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            toastr.success('', this.$t('main.delete_machine_success'),
                {
                  timeOut: 1500,
                  progressBar: true,
                  newestOnTop: true,
                  positionClass: 'toast-bottom-right',
                  preventDuplicates: true
                })
            this.getMachines()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    getMachines() {
      const path = api + 'machines'
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.machines = res.data.machines
            this.initMachineMetaData()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    editMachine(index) {
      this.getMachines()
      this.edit_machine = index
    },
    saveMachineInfo(m) {
      this.edit_machine = -1
      const path = api + 'machine/' + m.id
      axios.put(path, {
        "incident": m.incident,
        "incident_date": m.incident_date,
        "incident_info": m.incident_info,
        "contact": m.contact,
        "contact_tel": m.contact_tel,
        "contact_email": m.contact_email,
        "contact_info": m.contact_info,
        "account_id": this.id,
        "additional_info": m.additional_info
      }, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.getMachines()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
            toastr.error('', this.$t('main.edit_machine_failed'),
                {
                  timeOut: 1500,
                  progressBar: true,
                  newestOnTop: true,
                  positionClass: 'toast-bottom-right',
                  preventDuplicates: true
                })
          })
    },
    getYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
@font-face {
  src: url("../assets/namo.ttf");
  font-family: "logo_font";
}

footer {
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 450px) {
  footer {
    position: relative;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0.5em;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c81a1;
  border-radius: 10px;
}

.no-click {
  pointer-events: none;
  hover: none;
}
</style>