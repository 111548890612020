<template>
  <div class="container-xl animate__animated animate__fadeInUp"
       style="margin-top: 1.5em; max-width: 1880px !important;">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h5>{{ $t('devices.title') }}</h5>
          </div>
          <div class="col" style="text-align: right">
            <button type="button" class="btn btn-primary"
                    style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em"
                    @click="retrieveDevices" :disabled="busy">
              {{ $t('devices.retrieve') }}
            </button>
          </div>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-6" style="margin: 0.5em" v-if="!empty">
        <div class="col animate__animated animate__zoomInUp" v-for="(d, index) in devices" :key="index"
             style="margin-bottom: 0.5em">
          <div class="card h-100" style="width: 18rem;">
            <div class="card-body">
              <h5 class="card-title">{{ d.device_name }}</h5>
              <h6 class="card-subtitle mb-2 text-muted" style="color: #0983a3 !important;">{{ d.vendor_name }}</h6>
              <ul>
                <li>{{ $t('devices.vendor_id') }}: {{ d.vendor_id }}</li>
                <li>{{ $t('devices.product_id') }}: {{ d.product_id }}</li>
                <li>{{ $t('devices.version_number') }}: {{ d.version_number }}</li>
                <li>{{ $t('devices.device_path') }}: {{ d.device_path }}</li>
                <li>{{ $t('devices.instance_id') }}: {{ d.instance_id }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="container" style="max-width: 1880px; margin-top: 1em" v-if="empty">
        <div class="row">
          <div class="col">
            <h3>{{ $t('devices.emtpy_title') }}</h3>
            <p style="font-size: 1.5em">{{ $t('devices.empty_comment') }}</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <h6>{{ $t('devices.last_updated') }}: <span v-if="lastUpdated == ''">{{ $t('devices.na') }}</span><span v-else>{{ lastUpdated }}</span>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import * as toastr from "@/assets/toastr";
import {api} from "@/main";
import axios from "axios";

export default {
  name: "DeviceMonitoring",
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    machine_id: Number
  },
  data() {
    return {
      devices: [],
      machine: {},
      lastUpdated: "",
      busy: false,
      empty: true
    }
  }, methods: {
    retrieveDevices() {
      this.devices = []
      this.busy = true
      this.empty = true
      this.changeOpCode(this.machine, 2, "DEV-LST")
    },
    getCurrentDateTime() {
      const today = new Date();
      const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
      const time = today.getHours() + ":" + today.getMinutes()
      return date + ' ' + time
    },
    changeOpCode(m, n, opcode) {
      const path = api + 'machine/' + m.mac_address + '/' + n
      axios.post(path, {"opcode": opcode, "account_id": this.id}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    getMachineTriggerInit(id) {
      const path = api + 'machine/' + id
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.machine = res.data.machine
            this.subscribeBindChannels()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    getDevices() {
      const path = api + 'machine/' + this.machine.mac_address + '/devices'
      axios.get(path, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.empty = false
            this.busy = false
            this.lastUpdated = this.getCurrentDateTime()
            this.devices = res.data.devices
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    devicesMonitoringChannel() {
      var channel = this.pusher.subscribe('private-' + this.machine.mac_address.replace(/:/g, ""))
      // eslint-disable-next-line no-unused-vars
      var handler = function () {
        this.getDevices()
      }
      channel.bind("devices_update", this.getDevices)
    },
    subscribeBindChannels() {
      this.devicesMonitoringChannel()
    }
  },
  created() {
    scrollTo(0, 0)
    this.getMachineTriggerInit(this.machine_id)
  },
  mounted() {
  }
}
</script>

<style scoped>

ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

li {
  padding-left: 1em;
  text-indent: -1em;
}

li:before {
  content: "»";
  padding-right: 5px;
}

th {
  position: sticky;
}

tbody tr:nth-child(odd) {
  background-color: #3f5259;
  color: #fff;
}

tbody tr:nth-child(even) {
  background-color: #2e373b;
  color: #fff;
}

::-webkit-scrollbar {
  height: 0.5em !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c81a1;
  border-radius: 10px;
}

</style>