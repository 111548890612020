<template>
  <div class="container" @click="$refs.cmd.focus();">
    <div ref="terminal" id="container">
      <div v-if="banner" id="banner" style="margin-left: 1.5em">
        <h2 style="letter-spacing: 4px">{{$t('shell.version')}} [t#{{ tab_id }}]
          <span v-if="terminated" style="color: red">{{$t('shell.inactive')}}</span>
          <span v-if="!terminated" style="color: green">{{$t('shell.active')}}</span></h2>
        <p>{{$t('shell.current_directory')}}: <span style="color: #1482a1"><b>{{ dir }}</b></span></p>
        <p>{{$t('shell.start_help_line')}}</p>
        <p></p>
      </div>
      <output id="out" classs="container" ref="output" style="overflow: auto; max-height: 40em"></output>
      <div id="input-line" class="input-line">
        <div class="prompt">
          <div v-if="showemoji"><img src="https://leroderic.github.io/squid_remote/media/favicon.png"
                                     style="height: 1.5em; margin: 0"></div>
          <div v-if="!showemoji"><img src="https://leroderic.github.io/squid_remote/media/favicon_a.png"
                                      style="height: 1.5em; margin: 0"></div>
          <div>{{ banner.sign ? banner.sign : '>>' }}</div>
        </div>

        <input
            v-model="value"
            ref="cmd"
            @keydown.enter="cmd_enter($event)"
            @keydown.up="history_up()"
            @keydown.down="history_down()"
            @keydown.tab="cmd_tab($event)"
            class="cmdline"
            autofocus
        />
      </div>
    </div>
  </div>
</template>

<script>
import {api} from "@/main";
import axios from "axios";

export default {
  name: "ShellComponent",
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    machine_id: Number,
    tab_id: Number,
    shell_input: {
      required: false
    },
    banner: {
      type: Object,
      required: false,
      default: () => {
        return {
          emoji: {
            time: 750
          },
          sign: ">"
        };
      }
    },
    commands: {
      type: Array
    }
  },
  created() {
    this.getMachine(this.machine_id)
  },
  data() {
    return {
      machine: {},
      showemoji: true,
      value: "",
      history_: [],
      histpos_: 0,
      histtemp_: 0,
      dir: "/",
      terminated: false,
      firstc: true
    };
  },
  computed: {
    allcommands() {
      var tab = [
        {name: "help", desc: this.$t("shell.command_help")},
        {name: "clear", desc: this.$t("shell.command_clear")},
        {name: "terminate", desc: this.$t("shell.command_terminate")},
        {name: "restart", desc: this.$t("shell.command_restart")},
        {name: "set-timeout N", desc: this.$t("shell.command_set_timeout")},
        {name: "==", desc: this.$t("shell.help_version")}
      ];
      if (this.commands) {
        this.commands.forEach(({name, desc}) => {
          tab.push({name, desc});
        });
      }
      return tab;
    }
  },
  watch: {
    shell_input(val) {
      this.output(val);
      this.$parent.send_to_terminal = "";
    }
  },
  methods: {
    getCommandOutput() {
      const path = api + 'machine/' + this.machine.mac_address + "/shell_output/" + this.tab_id
      axios.get(path, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.dir = res.data.output.dir
            if (!this.firstc) {
              this.output(res.data.output.out)
            } else {
              this.firstc = false
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    postCommand(com) {
      const path = api + 'machine/' + this.machine.mac_address + "/shell/" + this.tab_id
      axios.post(path, {"command": com, "account_id": this.id}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    bindToOutputChannel() {
      var channel = this.pusher.subscribe('private-' + this.machine.mac_address.replace(/:/g, ""))
      channel.bind("shell_output_" + this.tab_id, this.getCommandOutput)
    },
    getMachine(id) {
      const path = api + 'machine/' + id
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.machine = res.data.machine
            this.createSession()
            this.bindToOutputChannel()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    createSession() {
      const path = api + 'machine/' + this.machine.mac_address + '/2'
      axios.post(path, {"opcode": "CMD-NEW-" + this.tab_id, "account_id": this.id}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.firstc = true
            this.postCommand("cd")
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    changeOpCode(n, opcode, first = false) {
      const path = api + 'machine/' + this.machine.mac_address + '/' + n
      axios.post(path, {"opcode": opcode, "account_id": this.id}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            if (first) {
              this.firstc = true
              this.postCommand("cd")
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    history_up() {
      if (this.history_.length) {
        if (this.history_[this.histpos_]) {
          this.history_[this.histpos_] = this.value;
        } else {
          this.histtemp_ = this.value;
        }
      }
      // up 38
      this.histpos_--;
      if (this.histpos_ < 0) {
        this.histpos_ = 0;
      }
      this.value = this.history_[this.histpos_]
          ? this.history_[this.histpos_]
          : this.histtemp_;
    },
    history_down() {
      if (this.history_.length) {
        if (this.history_[this.histpos_]) {
          this.history_[this.histpos_] = this.value;
        } else {
          this.histtemp_ = this.value;
        }
      }
      this.histpos_++;
      if (this.histpos_ > this.history_.length) {
        this.histpos_ = this.history_.length;
      }
      this.value = this.history_[this.histpos_]
          ? this.history_[this.histpos_]
          : this.histtemp_;
    },
    cmd_tab(e) {
      e.preventDefault();
    },
    cmd_enter() {
      if (this.value) {
        this.history_[this.history_.length] = this.value;
        this.histpos_ = this.history_.length;
      }
      //   Duplicate current input and append to output section.
      var line = this.$refs.cmd.parentNode.cloneNode(true);
      line.removeAttribute("id");
      line.classList.add("line");
      var input = line.querySelector("input.cmdline");
      input.autofocus = false;
      input.readOnly = true;
      this.$refs.output.appendChild(line);
      if (this.value && this.value.trim()) {
        var args = this.value.split(" ").filter(function (val) {
          return val;
        });
        var cmd = args[0].toLowerCase();
        args = args.splice(1); // Remove cmd from arg list.
      }
      if (cmd == "clear") {
        this.$refs.output.innerHTML = "";
        this.value = "";
      } else if (cmd == "restart") {
        this.terminated = false
        this.changeOpCode(2, "CMD-RES-" + this.tab_id, true)
        this.value = "";
      } else if (cmd == "terminate") {
        if (!this.terminated) {
          this.dir = ""
          this.output('<p style="color: red" v-show="terminated">Session terminated, use "restart" to restart it</p>')
          this.terminated = true
          this.changeOpCode(2, "CMD-FIN-" + this.tab_id)
        }
        this.value = "";
      } else if (cmd == "help") {
        var commandsList = this.allcommands.map(({name, desc}) => {
          if (desc) {
            return `${name}: ${desc}`;
          }
          return name;
        });
        this.output(
            '<div class="ls-files" style="color: red; margin-left: 0.2em">' + commandsList.join("<br>") + "</div>"
        );
      } else {
        if (this.commands) {
          this.commands.forEach(a => {
            if (cmd == a.name) {
              this.output(a.get());
              return;
            }
          });
        }
        if (this.value.trim() != "") {
          this.postCommand(this.value)
        }
        this.value = "";
      }
      // Clear/setup line for next input.
      document.getElementById("out").scrollTop = document.getElementById("out").scrollHeight
    },
    output(html) {
      this.$refs.output.insertAdjacentHTML(
          "beforeend",
          "<pre style='color: white'>" + html + "</pre>"
      );
      this.value = "";
      document.getElementById("out").scrollTop = document.getElementById("out").scrollHeight

    }
  },
  mounted() {
    if (this.banner.emoji.time) {
      setInterval(() => {
        this.showemoji = !this.showemoji;
      }, this.banner.emoji.time);
    }
  }
};
</script>

<style scoped>
@media (max-width: 450px) {
  footer {
    position: relative;
  }
}

html, body {
  height: 100%;
}

#container {
  color: white;
  background-color: black;
  font-size: 11pt;
  font-family: Inconsolata, monospace;
  display: block;
}

#container output {
  clear: both;
  width: 100%;
}

#banner {
  margin-bottom: 3em;
}

img {
  margin-right: 20px;
}

.input-line {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-align: stretch;
  display: -moz-box;
  -moz-box-orient: horizontal;
  -moz-box-align: stretch;
  box-orient: horizontal;
  box-align: stretch;
  clear: both;
}

.input-line > div:nth-child(2) {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  box-flex: 1;
}

.prompt {
  white-space: nowrap;
  color: #0c81a1;
  margin-right: 7px;
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  box-pack: center;
  box-orient: vertical;
  user-select: none;
}

.cmdline {
  outline: none;
  background-color: transparent;
  margin: 0;
  width: 100%;
  font: inherit;
  border: none;
  color: inherit;
}

.ls-files {
  height: 45px;
  column-width: 100px;
}

::-webkit-scrollbar {
  height: 0.5em !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c81a1;
  border-radius: 10px;
}
</style>
