<template>
  <div>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css">
    <div class="container-xl animate__animated animate__fadeInUp"
         style="margin-top: 1.5em; max-width: 1880px !important; margin-bottom: 0.5em">
      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item" v-for="(tab, index) in instances" :key="index">
              <a class="nav-link" href="javascript:void(0)" :id="'consoleTab' + tab"
                 @click="changeTab(tab)">{{ $t('shell.window') }} {{ tab }}
                <i class="fas fa-times" @click="closeTab(tab)" style="margin-left: 0.5em; color: red"
                   v-if="instances.length > 1"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)" @click="addNewTab" tabindex="-1" aria-disabled="true"
                 v-if="instances.length < 5">
                {{ $t('shell.new_window') }}</a>
              <a class="nav-link disabled" href="javascript:void(0)" tabindex="-1" aria-disabled="true"
                 v-else>{{ $t('shell.new_window') }}</a>
            </li>
          </ul>
        </div>
        <div class="card-body"
             style="background-color: black; min-height: 80vh; margin-left: 0.5em; margin-right: 0.5em">
          <shell-component v-for="(tab, index) in instances" :key="index" :id="id" :type="type" :token="token"
                           :pusher="pusher"
                           :machine_id="$route.params.id" :tab_id="tab" v-show="viewing_tab == tab"></shell-component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ShellComponent from "@/components/ShellComponent";
import {api} from "@/main";
import axios from "axios";

export default {
  name: "ReverseShell",
  components: {ShellComponent},
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    machine_id: Number,
  },
  data() {
    return {
      viewing_tab: 1,
      instances: [1],
      off_instances: [2, 3, 4, 5],
      machine: {}
    }
  },
  methods: {
    getMachine(id) {
      const path = api + 'machine/' + id
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.machine = res.data.machine
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    addNewTab() {
      this.instances.push(this.off_instances[0])
      var i = this.off_instances.indexOf(this.off_instances[0])
      this.off_instances.splice(i, 1)
    },
    closeTab(index) {
      this.changeOpCode(2, "CMD-FIN-" + index)
      var i = this.instances.indexOf(index)
      this.off_instances.push(index)
      this.instances.splice(i, 1)
      this.changeTab(this.instances[this.instances.length - 1])
    },
    changeOpCode(n, opcode) {
      const path = api + 'machine/' + this.machine.mac_address + '/' + n
      axios.post(path, {"opcode": opcode, "account_id": this.id}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    changeTab(index) {
      var prev = document.getElementById("consoleTab" + this.viewing_tab)
      if (prev) {
        prev.classList.remove("active")
      }
      document.getElementById("consoleTab" + index).classList.add("active")
      this.viewing_tab = index
    }
  },
  mounted() {
    this.getMachine(this.id)
    this.changeTab(this.viewing_tab)
  }
};
</script>

<style scoped>
@media (max-width: 450px) {
  footer {
    position: relative;
  }
}

::-webkit-scrollbar {
  height: 0.5em !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c81a1;
  border-radius: 10px;
}
</style>