<template>
  <div>
    <div class="table-responsive">
      <table class="table table-sm table-hover">
        <thead style="background-color: #2596be; color: white">
        <tr>
          <th scope="col">{{ $t('download_codes.id') }}</th>
          <th scope="col">{{ $t('download_codes.platform') }}</th>
          <th scope="col">{{ $t('download_codes.author') }}</th>
          <th scope="col">{{ $t('download_codes.creation_date') }}</th>
          <th scope="col">{{ $t('download_codes.download_date') }}</th>
          <th scope="col">{{ $t('download_codes.connection_date') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(code, index) in split_codes[current_view - 1]" :key="index">
          <th>{{ code.id }}</th>
          <th>{{ code.platform }}</th>
          <th>{{ code.issuing_account }}</th>
          <th>{{ code.creation_date }}</th>
          <th>
            <span v-if="code.download_date != '' && code.download_date != null">{{ code.download_date }}
            <button class="btn btn-sm btn-success" style="margin-left: 0.5em;"
                    @click="setDownloadEnable(code.id, true)">
              {{ $t('download_codes.enable') }}
            </button>
            </span>
            <span v-else>{{ $t('download_codes.not_available') }}
            <button class="btn btn-sm btn-danger" style="margin-left: 0.5em;"
                    @click="setDownloadEnable(code.id, false)">
              {{ $t('download_codes.disable') }}
            </button>
            </span>
          </th>
          <th>
            <span v-if="code.used_date != '' && code.used_date != null">{{ code.used_date }}
            <button class="btn btn-sm btn-success" style="margin-left: 0.5em;"
                    @click="setConnectionEnable(code.id, true)">
              {{ $t('download_codes.enable') }}
            </button>
            </span>
            <span v-else>{{ $t('download_codes.not_available') }}
            <button class="btn btn-sm btn-danger" style="margin-left: 0.5em;"
                    @click="setConnectionEnable(code.id, false)">
              {{ $t('download_codes.disable') }}
            </button>
            </span>
          </th>
        </tr>
        </tbody>
      </table>
    </div>
    <nav aria-label="Page navigation example" v-if="split_codes.length != 0">
      <ul class="pagination">
        <li class="page-item disabled" v-if="current_view == 1">
          <a class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item" v-if="current_view != 1" @click="decreaseView">
          <a class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item disabled"><a class="page-link" style="background-color: #2596be; color: white"
                                          href="javascript:void(0)"><b>{{ current_view }}</b></a></li>
        <li class="page-item" v-if="current_view != split_codes.length" @click="increaseView">
          <a class="page-link" href="javascript:void(0)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
        <li class="page-item disabled" v-if="current_view == split_codes.length">
          <a class="page-link" href="javascript:void(0)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {api} from "@/main";
import axios from "axios";

export default {
  name: "DownloadCodesManagement",
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    lang: String
  },
  data() {
    return {
      current_view: 1,
      view_codes: [],
      split_codes: []
    }
  },
  created() {
    this.getCodes()
  },
  methods: {
    decreaseView() {
      this.current_view -= 1
    },
    increaseView() {
      this.current_view += 1
    },
    setDownloadEnable(id, val) {
      const path = api + 'download'
      axios.put(path, {"id": id, "download_enable": val}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.getCodes()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    setConnectionEnable(id, val) {
      const path = api + 'download'
      axios.put(path, {"id": id, "connect_enable": val}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.getCodes()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    getCodes() {
      this.split_codes = []
      const path = api + 'downloads'
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.splitCodes(res.data.download_codes)
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    splitCodes(codes) {
      var i, j, temparray, chunk = 10
      for (i = 0, j = codes.length; i < j; i += chunk) {
        temparray = codes.slice(i, i + chunk)
        this.split_codes.push(temparray)
      }
      this.view_codes = this.split_codes[0]
    }
  }
}
</script>

<style scoped>

</style>