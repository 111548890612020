import {createApp} from 'vue'
import {VueCookieNext} from 'vue-cookie-next'
import { createI18n } from "vue-i18n"
import App from './App.vue'
import router from './router'
import mitt from 'mitt'
import 'bootstrap'
import VueApexCharts from "vue3-apexcharts"
import 'bootstrap/dist/css/bootstrap.min.css'
import {messages} from './assets/msgs.js'
export const bus = mitt()
export const api = process.env.VUE_APP_BACKEND_URL
export const SECURE_COOKIES = Boolean(process.env.VUE_COOKIES_SAFE)
export const i18n = new createI18n({
    locale: 'EN',
    messages
})

const app = createApp(App)

app.use(i18n)
app.use(VueCookieNext)
app.use(VueApexCharts)
app.use(router)
app.mount('#app')
