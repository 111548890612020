// eslint-disable-next-line no-unused-vars
export const messages = {
    EN: {
        admin_help: {
            title: "Administration panel",
            subtitle: "From here you will be able to enable/disabled download codes, create accounts and view the registered logs.",
            dcodes: "Download codes",
            dcodest: "The download codes act as the access key in a client connection. They are sent via email through the main page. Each code is linked to an email, which needs to be introduced in the remote machine to authorize the connection. If the download code's email and the introduced one do not match, the connection is refused. Each code allows one client download for the given platform and one connection. The registry of when the codes were used is shown in this section. From this section you can also activate or deactivate either the download or connection privileges of each code.",
            logs: "Logs",
            logst: "Two types of logs are registered: login logs and machine logs. Login logs keep a registry of all the users/services that have authenticated with the app (this includes machines and Pusher). The machines log keeps a record of all the actions performed over an specific machine.",
            accounts: "Account management",
            accounts1: "Each account has an expiration date, after which the user loses the ability to log in. There are three types of accounts:",
            admin: "Admin account",
            admint: "Master role. Expires in 1000 years.",
            tech: "Technician account",
            techt: "Similar to admin but without the ability to manage accounts. Expires in 3 years.",
            demo: "Demo account",
            demot: "Same as a technician. Expires 2 days after creation.",
            account2: "Different actions can be performed for a given account (except on admin accounts):",
            editdata: "Edit account information and expiration date.",
            regenpswd: "Regenerate account's password (new one sent via email).",
            deactacc: "Deactivate an account.",
            reactacc: "Reactivate an account.",
            butto: "This actions can be performed using the following buttons (that are attached to each row):",
            editbt: "Edit button",
            savebt: "Save button",
            deabt: "Deactivate account button",
            reabt: "Reactivate account button",
            regbt: "Regenerate password button",
        },
        main_help: {
            title: "This is squid-remote's main page",
            subtitle: "From this page you will be able to connect to the machines, send download links as well as manage the download codes, user accounts and view the logs.",
            navbar: "Top navbar",
            navbar_text: "You may use the navbar to access the following functions:",
            sendDL: "Send client download link.",
            refresh: "Refresh the main page.",
            admin: "Go to the administration section.",
            closeHelp: "Close this window.",
            closeSession: "Sign out.",
            managing_machine: "Machine management",
            machine1: "Each machine is displayed in a card style with access to all its information. The system's hardware and OS information is provided by the client and cannot be modified. The case information fields can be modified using the edit button and saved using the save button. You can also delete the machine by clicking on the delete button.",
            machine2: "Once a machine has reported to the server its successful connection, all the actions buttons will appear. If the machine is offline or not yet connected, only the 'Verify connection' button will be available.",
            con: "Redirects you to the machine dashboard.",
            test: "Test the connection with the machine (ping).",
            msg: "Lets you send a message to the machine.",
            discon: "Disconnects the remote machine."
        },
        dashboard_help: {
            title: "Welcome to squid-remote's dashboard!",
            desc: "This is the main operations screen. From here you will be able to monitor the running processes, capture the network traffic, monitor the system's performance as well as execute remote shell into the remote machine.",
            interface: "Dashboard interface",
            tl_section: "Top-left section",
            tr_section: "Top-right section",
            processes: "Processes monitoring.",
            network: "Network traffic capture.",
            devices: "Connected devices retrieval.",
            monitor: "System performance monitor.",
            shell: "Remote shell.",
            send_msg: "Send message to the remote machine.",
            con_test: "Connection test with machine.",
            case_info: "Machine and case information.",
            go_back: "Go back to machines page.",
            actions_bt: "Actions button (disconnect).",
            proc_desc: "The process monitoring component will recover all the processes running on the remote machine and show the following info:",
            process_mon: "Process Monitoring",
            pid: "PID",
            pidt: "Process identifier.",
            name: "Name",
            namet: "Process name.",
            status: "Status",
            statust: "Current execution status (Running, Stopped).",
            cpu: "CPU",
            cput: "Usage in % of the CPU.",
            cpu_aff: "CPU Aff.",
            cpu_afft: "Amount of CPUs the process is allowed to use.",
            priority: "Priority",
            priorityt: "The priority of the process.",
            memory: "Memory",
            memoryt: "Current memory usage.",
            disk: "Disk",
            diskt: "Usage in % of the disk.",
            threads: "Threads",
            threadst: "Number of threads instantiated by the process.",
            creation: "Creation",
            creationt: "Date and time of creation of the process.",
            user: "User",
            usert: "User who created the process in the first place.",
            path: "Path",
            patht: "Directory of the process.",
            net_sniff: "Network Traffic Sniffer",
            net_sniff_desc: "This component allows you to capture the network traffic on the remote machine. It has two capture modes: by packet amount or by time. You can set the capture preferences and filters through the component's header as well as the capture device.",
            net_sniff_desc2: "The captured packets will be shown in three sections: a table with al the captured packets, a summary of the packet and the raw data from the packet when a packet is clicked. Example:",
            cap_pack: "Captured packets",
            pack_info: "Packet information",
            pack_raw: "Raw",
            con_dev_ret: "Connected Devices Retrieval",
            con_dev_desc: "This component will attempt to retrieve all devices connected to the Universal Serial Bus. It is possible that some devices may appear more than once. This is because if a device has several buttons or other controls, they will also connected to the bus and therefore listed here. The following is an example of the information recovered of a USB device.",
            remo_shell: "Remote Shell",
            remo_shell_desc: "The name says it all, this component will allow you to execute shell commands on the remote machines.",
            remo_shell_win: "Windows client",
            remo_shell_wint: "The shell acts as a normal shell. When accessing or exiting a directory, the session stays in the same directory (between commands) if not indicated the contrary.",
            remo_shell_lin: "Linux client",
            remo_shell_lint: "You must concatenate the commands with pipes, since the backend does not keep the working directory between command executions. Remember that \"|\" character is used in Linux to pipe command results into other command inputs.\n",
            remo_shell_com: "The remote shell has some built-in commands too:",
            rs_help: "help",
            rs_helpt: "Shows all available commands.",
            rs_clear: "clear",
            rs_cleart: "Clears all the output on the current shell session.",
            rs_terminate: "terminate",
            rs_terminatet: "Terminates the current shell session.",
            rs_restart: "restart",
            rs_restartt: "Restarts the shell session (in case it was previously terminated or if it crashed).",
            rs_settm: "set-timeout",
            rs_settmt: "Sets the maximum time the backend will wait for the command output (if a command takes a longer time, a higher timeout should be set)."
        },
        about: {
            author: "Developed by",
            about: "About the project",
            t1: "squid-remote is a crossplatform remote forensic analysis system on endpoints. Intended for a post-incident use, allows multiple information monitoring (processes, network traffic, device connection, ...) as well as the remote usage of different IR tools (memory imaging, log collection, reverse shell, ...).",
            t2: "It was developed as my final degree project in Computer Engineering. It consists in three main parts: client, server and interface. The interface is built in Vue.js and the client and server in Python (the server uses the Flask framework).",
            t3: "The main goal was to build a tool that could be used by anyone anywhere. Anyone with basic computer usage knowledge can run the client and allow remote intervention by a technician. As simple as that. No need to use VPNs or special socket configuration nor to install any libraries or requirements in the remote machine. Once the intervention is completed, the client can be deleted and no trace will be left on the machine.",
            t4: "All communication is done via HTTPS and the data transferring between machine and server with a Redis database, which is used as cache. The interface is then synchronized  with the server and machine events using Pusher."
        },
        login: {
            username: "Username",
            password: "Password",
            sign_in: "Sign in",
            req_demo: "Wanna know more? Request a demo!",
            copyright: "Copyright © ",
            first_name: "First name",
            last_name: "Last name",
            email: "Email address",
            demo_reason: "Why do you want a demo?",
            request: "Request",
            return: "Go back",
            login_failed: "Incorrect username or password"
        },

        main: {
            help: "Help",
            chelp: "Close help",
            registered_machines: "Registered machines",
            send_dl_link: "Send download link",
            refresh: "Refresh",
            administration: "Administration",
            log_out: "Log out",
            email: "Email address",
            language: "Language",
            select: "Select",
            spanish: "Spanish",
            english: "English",
            catalan: "Catalan",
            platform: "Platform",
            windows: "Windows x64",
            linux: "Linux x64",
            close: "Close",
            send: "Send",
            send_msg_machine: "Send message to machine",
            hostname: "Hostname",
            architecture: "Architecture",
            processor: "Processor",
            ram_memory: "RAM memory",
            system: "System",
            op_system: "Operative system",
            platform_release: "Platform release",
            platform_version: "Platform version",
            network_cards: "Network cards",
            reported_incident: "Reported incident",
            incident: "Incident",
            date_time: "Date and time",
            more_info: "More info",
            contact: "Contact",
            name_lname: "Full name",
            phone_number: "Phone number",
            additional_notes: "Additional notes",
            connect: "Connect",
            verify_con: "Verify connection",
            send_msg: "Send message",
            disconnect: "Disconnect",
            copyright: "Copyright © ",
            invalid_email: "Invalid email address.",
            send_dl_link_failed: "Could not send download link.",
            verify_con_failed: "Could not communicate with remote machine.",
            verify_con_success: "Remote machine is connected.",
            delete_machine_success: "Machine deleted successfully.",
            edit_machine_failed: "Changes could not be saved."
        },
        dashboard: {
            processes: "Processes",
            network: "Network",
            devices: "Devices",
            performance: "Performance",
            shell: "Shell",
            send_msg: "Send message",
            test_connection: "Test connection",
            machine_info: "Machine info",
            back_to_machines: "Back to dashboard",
            disconnect: "Disconnect",
            copyright: "Copyright © ",
            verify_con_failed: "Could not communicate with remote machine.",
            verify_con_success: "Machine connected.",
            actions: "Actions",
            shutdown: "Shutdown",
            restart: "Restart",
            help: "Help",
            waiting_data: "Waiting on data from the remote machine"
        },
        processes: {
            title: "Process currently running",
            pid: "PID",
            name: "Name",
            status: "Status",
            cpu: "CPU",
            cpu_af: "CPU AFF.",
            priority: "Priority",
            memory: "Memory",
            disk: "Disk",
            threads: "Threads",
            creation: "Creation",
            user: "User",
            path: "Path"
        },
        shell: {
            window: "Window",
            new_window: "New window",
            active: "active",
            inactive: "inactive",
            current_directory: "Current directory",
            version: "squid-shell v1.0",
            start_help_line: "Shell or platform specific commands ONLY -- Enter \"help\" for more information.",
            command_help: "Show all the commands that are available",
            command_clear: "Clear the terminal of all output",
            command_terminate: "Terminate current remote shell version",
            command_restart: "Restart remote shell session",
            command_set_timeout: "Set time out for current shell (N must be >= 1)",
            help_version: "squid-shell v1.0 is based on @HalasProject's frontend implementation vue-shell :=="
        },
        admin: {
            title: "Administration",
            back_to_main: "Go back",
            refresh: "Refresh",
            download_codes: "Download codes",
            login_logs: "Login logs",
            machine_logs: "Machine logs",
            account_management: "Accounts management",
            copyright: "Copyright",
            about: "About squid-remote"
        },
        download_codes: {
            id: "ID",
            platform: "Platform",
            email: "Email",
            author: "Issued by",
            creation_date: "Creation date",
            download_date: "Download date",
            connection_date: "Connection date",
            not_available: "N/A",
            disable: "Disable",
            enable: "Enable"
        },
        login_logs_view: {
            id: "ID",
            username: "Username",
            date: "Date",
            extension_login: "Extended session",
            agent_type: "Agent type",
            debug_auth: "Debug auth",
            zero: "User",
            one: "Machine",
            two: "Pusher/Other services",
            yes: "Yes",
            no: "No"
        },
        machine_logs_view: {
            select_machine: "Select a registered machine",
            id: "ID",
            username: "Username",
            operation: "Operation",
            date: "Date",
            ip: "IP",
            previous_value: "Previous value",
            new_value: "New value"
        },
        accounts_management: {
            email: "Email",
            id: "ID",
            username: "Username",
            first_name: "First name",
            last_name: "Last name",
            telf: "Phone number",
            type: "Type",
            expiration_date: "Expiration date",
            not_available: "N/A",
            machine: "Machine",
            admin: "Administrator",
            tech: "Technician",
            active: "Active",
            yes: "Yes",
            no: "No",
            actions: "Actions",
            new_account: "New account",
            create: "Create",
            close: "Close",
            create_account_error: "Could not create account. Check username does not exist already or figure out something.",
            new_account_details: "New account details",
            password: "Password",
            expires: "Expires",
            demo_account: "Demo account",
            new_account_password_advice: "Make sure to write down the password since this will be the first and last time you will be able to see it."
        },
        network_traffic: {
            emtpy_title: "Nothing here!",
            empty_comment: "Let's go packet fishing?",
            raw: "Raw",
            captured_packets: "Captured packets",
            packet_info: "Packet information",
            packet_filters: "Packet filters",
            capture_controls: "Capture controls",
            id: "ID",
            ttl: "TTL",
            time: "Time",
            source: "Source",
            destination: "Destination",
            version: "Version",
            ip_protocol: "IP Protocol",
            src: "Src",
            dst: "Dst",
            ip_wversion: "Internet Procotol Version (IP)",
            tcp: "Transmission Control Protocol (TCP)",
            src_port: "Src Port",
            dst_port: "Dst Port",
            seq: "Seq",
            ack: "Ack",
            len: "Len",
            padding: "Padding",
            udp: "User Datagram Protocol (UDP)",
            dns: "Domain Name System (DNS)",
            opcode: "opcode",
            dns_question_records: "DNS Question Records",
            qname: "qname",
            qtype: "qtype",
            qclass: "qclass",
            icmp: "Internet Control Message Protocol (ICMP)",
            type: "Type",
            code: "Code",
            rcode: "rcode",
            qdcount: "qdcount",
            length: "Length",
            checksum: "Checksum",
            info: "Info",
            capture_options: "Traffic capture options",
            capture: "Capture",
            stop: "Stop",
            save_data_json: "Save data as JSON",
            capture_mode: "Capture mode",
            capture_packets: "Capture by packet amount",
            capture_packets_selected: "Number of packets to capture",
            capture_time_selected: "Capture during (seconds)",
            capture_time: "Capture by time",
            show_options: "Show options",
            hide_options: "Hide options",
            reset: "Reset",
            capture_card: "Capture device",
            select: "Select"
        },
        devices: {
            emtpy_title: "Nothing here!",
            empty_comment: "Click on 'Retrieve' and start listing the devices.",
            title: "Connected USB devices",
            retrieve: "Retrieve",
            last_updated: "Last updated",
            na: "N/A",
            vendor_id: "Vendor ID",
            product_id: "Product ID",
            version_number: "Version number",
            device_path: "Device path",
            instance_id: "Instance id"
        },
        hardware_monitor: {
            system_status: "System status",
            cpu_usage: "CPU Load",
            current_freq: "Current freq.",
            max_freq: "Max freq.",
            memory_usage: "Memory usage",
            in_use: "In use",
            total: "Total",
            swap_usage: "Swap usage",
            performance_charts: "Performance charts",
            system_stats: "System statistics",
            since_start: "Since start",
            data_written_d: "Data written in disks",
            data_read_d: "Data read from disks",
            bytes: "bytes",
            data_sent_n: "Data sent over network",
            data_rec_n: "Data received over network",
            cpu_load_dstr: "CPU load distribution",
            physical_cores: "physical cores",
            logical_cores: "logical cores",
            core: "Core",
            gpus: "GPUs (NVIDIA only)",
            used_mem: "Used memory",
            total_mem: "Total memory",
            temperature: "Temperature",
            uuid: "UUID",
            disks_drives: "Disks drives",
            disk: "Disk"
        }
    },
    ES: {
        admin_help: {
            title: "Panel de administración",
            subtitle: "Desde aquí podrás habilitar / deshabilitar los códigos de descarga, crear cuentas y ver los logs.",
            dcodes: "Códigos de descarga",
            dcodest: "Los códigos de descarga actúan como clave de acceso en una conexión del cliente. Se envían por correo electrónico a través de la página principal. Cada código está vinculado a un correo electrónico, que debe introducirse en la máquina remota para autorizar la conexión. Si el correo electrónico del código de descarga y el introducido no coinciden, la conexión es rechazada. Cada código permite una descarga de cliente para la plataforma dada y una conexión. En esta sección se muestra el registro de cuándo se usaron los códigos. Desde esta sección también puede activar o desactivar los privilegios de descarga y de conexión de cada código. ",
            logs: "Registros",
            logst: "Se mantienen dos tipos de registros: registros de inicio de sesión y registros de máquina. Los registros de inicio de sesión mantienen un registro de todos los usuarios / servicios que se han autenticado con la aplicación (esto incluye máquinas y Pusher). El registro de máquinas mantiene un registro de todos las acciones realizadas sobre una máquina específica. ",
            accounts: "Gestión de cuentas",
            accounts1: "Cada cuenta tiene una fecha de vencimiento, después de la cual el usuario pierde la capacidad de iniciar sesión. Hay tres tipos de cuentas:",
            admin: "Cuenta de administrador",
            admint: "Rol maestro. Caduca a los 1000 años.",
            tech: "Cuenta de técnico",
            techt: "Similar al administrador, pero sin la capacidad de administrar cuentas. Caduca a los 3 años.",
            demo: "Cuenta demo",
            demot: "Igual que un técnico. Caduca 2 días después de la creación.",
            account2: "Se pueden realizar diferentes acciones para una cuenta determinada (excepto en cuentas de administrador):",
            editdata: "Editar la información de la cuenta y la fecha de vencimiento.",
            regenpswd: "Regenerar la contraseña de la cuenta (una nueva enviada por correo electrónico).",
            deactacc: "Desactivar una cuenta.",
            reactacc: "Reactivar una cuenta.",
            butto: "Estas acciones se pueden realizar utilizando los siguientes botones (que se adjuntan a cada fila):",
            editbt: "Botón editar",
            savebt: "Botón guardar",
            deabt: "Botón descativar cuenta",
            reabt: "Botón reactivar cuenta",
            regbt: "Botón de regenerar contraseña"
        },
        main_help: {
            title: "Esta es la página principal de squid-remote",
            subtitle: "Desde esta página podrás conectarte a las máquinas, enviar enlaces de descarga, así como administrar los códigos de descarga, las cuentas de usuario y ver los registros.",
            navbar: "Barra de navegación",
            navbar_text: "Puedes utilizar la barra de navegación para acceder a las siguientes funciones:",
            sendDL: "Enviar enlace de descarga.",
            refresh: "Refrescar la página principal.",
            admin: "Ir al panel de administración.",
            closeHelp: "Cerrar esta ventana.",
            closeSession: "Cerrar sesión.",
            managing_machine: "Gestión de máquinas",
            machine1: "Cada máquina se muestra en un estilo de tarjeta con acceso a toda su información. La información del sistema operativo y del hardware del sistema la proporciona el cliente y no se puede modificar. Los campos de información del caso se pueden modificar con el botón de edición y guardar con el botón de guardar. También puede eliminar la máquina haciendo clic en el botón Eliminar.",
            machine2: "Una vez que una máquina ha informado al servidor de su conexión exitosa, aparecerán todos los botones de acciones. Si la máquina está fuera de línea o aún no está conectada, solo estará disponible el botón 'Verificar conexión'.",
            con: "Redirecciona al tablero de la máquina.",
            test: "Test de conexión con la máquina (ping).",
            msg: "Permite enviar un mensaje a la máquina.",
            discon: "Desconecta la máquina remota."
        },
        about: {
            author: "Desarrollado por",
            about: "Acerca del proyecto",
            t1: "squid-remote es un sistema de análisis forense remoto multiplataforma sobre endpoints. Diseñado para un uso posterior al incidente, permite el monitoreo de información múltiple (procesos, tráfico de red, conexión de dispositivos, ...) así como el uso remoto de diferentes herramientas RI (imágenes de memoria, recopilación de registros, shell inverso, ...). ",
            t2: "Fue desarrollado como mi proyecto de fin de carrera de Ingeniería Informática. Consta de tres partes principales: cliente, servidor e interfaz. La interfaz está construida en Vue.js y el cliente y servidor en Python (el servidor usa el framework Flask ). ",
            t3: "El objetivo principal era crear una herramienta que pudiera ser utilizada por cualquier persona en cualquier lugar. Cualquier persona con conocimientos básicos de uso de un ordenador puede ejecutar el cliente y permitir la intervención remota de un técnico. Tan simple como eso. No es necesario utilizar ni VPNs ni configuración especial de sockets ni instalar bibliotecas o requisitos en la máquina remota. Una vez finalizada la intervención, el cliente puede ser eliminado y no habrá ningún rastro en la máquina. ",
            t4: "Toda la comunicación se realiza a través de HTTPS y la transferencia de datos entre la máquina y el servidor con una base de datos de Redis, que se utiliza como caché. La interfaz se sincroniza con el servidor y los eventos de la máquina mediante Pusher"
        },
        dashboard_help: {
            title: "¡Bienvenido al panel de control de squid-remote!",
            desc: "Esta es la página principal de operaciones. Desde aquí podrás monitorizar procesos, capturar el tráfico de red, monitorizar el rendimiento del equipo remoto además de ejcutar comandos en una shell remota.",
            interface: "Panel de Control",
            tl_section: "Parte superior izquierda",
            tr_section: "Parte superior derecha",
            processes: "Monitorización de procesos.",
            network: "Captura de tráfico de red.",
            devices: "Listado de dispositivos conectados.",
            monitor: "Monitor de rendimiento del sistema.",
            shell: "Shell remota.",
            send_msg: "Enviar mensaje a máquina remota.",
            con_test: "Test de conexión con la máquina.",
            case_info: "Información de la máquina y del caso.",
            go_back: "Volver a listado de máquinas.",
            actions_bt: "Botón de acciones (desconectar).",
            proc_desc: "La monitorización de procesos mostrará la siguiente información de cada proceso que se esté ejecutando en la máquina remota:",
            process_mon: "Monitorización de procesos",
            pid: "PID",
            pidt: "Identificador de proceso.",
            name: "Nombre",
            namet: "Nombre del proceso.",
            status: "Estado",
            statust: "Estado de ejecución actual (Running, Stopped).",
            cpu: "CPU",
            cput: "Uso en % de la CPU.",
            cpu_aff: "CPU Af.",
            cpu_afft: "Cantidad de CPUs que el proceso tiene permitido usar.",
            priority: "Prioridad",
            priorityt: "La prioridad que tiene el proceso.",
            memory: "Memoria",
            memoryt: "Uso de memoria actual.",
            disk: "Disco",
            diskt: "Uso en % de discos.",
            threads: "Hilos",
            threadst: "Número de hilos creados por el proceso.",
            creation: "Creación",
            creationt: "Fecha y hora de creación del proceso.",
            user: "Usuario",
            usert: "Usuario que instanció el proceso.",
            path: "Ruta",
            patht: "Directorio del proceso.",
            net_sniff: "Captura de Tráfico de Red",
            net_sniff_desc: "Este componente permite capturar el tráfico en la red de la máquina remota. Tiene dos modos de captura: por cantidad de paquetes o por tiempo. Se pueden configurar los filtros y las condiciones de captura" +
                " a traves del controlador del componente.",
            net_sniff_desc2: "Los paquetes capturados apareceran con tres secciones: en la tabla con todos los paquetes capturados, y en un resumen y con el paquete tal cual cuando se clica en un paquete de la tabla. Ejemplo:",
            cap_pack: "Paquetes capturados",
            pack_info: "Información de paquete",
            pack_raw: "Raw",
            con_dev_ret: "Listado de Dispositivos Conectados",
            con_dev_desc: "Este componente intentará listar todos los dispositivos conectados al Bus Serie Universal. Es posible que algún dispositivo aparezca más de una vez. Esto es" +
                " debido a que el dispositivo tiene más de un botón o elemento conectado al bus. El siguiente ejemplo muestra la información recuperada de un dispositivo USB.",
            remo_shell: "Shell remota",
            remo_shell_desc: "El nombre lo dice todo. Este componente permite ejecutar comandos por consola en la máquina remota.",
            remo_shell_win: "Cliente Windows",
            remo_shell_wint: "Actúa como una consola normal. Cuando se accede a un directorio, la sesión permanece en el directorio si no se indica lo contrario.",
            remo_shell_lin: "Cliente Linux",
            remo_shell_lint: "Se deben concatenar los comandos con tuberías, puesto que el backend en este caso no mantiene el directorio de trabajo después de la ejecución de cada comando. Recuerda que en Linux" +
                "se usa el carácter \"|\" para mandar la salida de un comando a la entrada de otro.",
            remo_shell_com: "La shell remota tiene algunos comandos integrados:",
            rs_help: "help",
            rs_helpt: "Muestra todos los comandos disponibles.",
            rs_clear: "clear",
            rs_cleart: "Limpia todas las salidas de la pantalla para la sesión actual.",
            rs_terminate: "terminate",
            rs_terminatet: "Termina la shell remota actual.",
            rs_restart: "restart",
            rs_restartt: "Reincia la shell remota (en caso de que se haya terminado antes o haya caído).",
            rs_settm: "set-timeout",
            rs_settmt: "Establece el tiempo máximo que el backend esperará a la salida del comando (si un comando da timeout, se deberá incrementar el timeout)."
        },
        login: {
            username: "Usuario",
            password: "Contraseña",
            sign_in: "Acceder",
            req_demo: "¿Quieres saber más? ¡Pide una demo!",
            copyright: "Copyright © ",
            first_name: "Nombre",
            last_name: "Apellidos",
            email: "Correo electrónico",
            demo_reason: "¿Por qué quieres una demo?",
            request: "Solicitar",
            return: "Volver",
            login_failed: "El usuario o la contraseña son incorrectos."
        },
        main: {
            help: "Ayuda",
            chelp: "Cerrar ayuda",
            registered_machines: "Máquinas registradas",
            send_dl_link: "Enviar enlace de descarga",
            refresh: "Refrescar",
            administration: "Administración",
            log_out: "Cerrar sesión",
            email: "Correo electrónico",
            language: "Idioma",
            select: "Selecciona",
            spanish: "Castellano",
            english: "Inglés",
            catalan: "Catalán",
            platform: "Plataforma",
            windows: "Windows x64",
            linux: "Linux x64",
            close: "Cerrar",
            send: "Enviar",
            send_msg_machine: "Enviar mensaje a máquina",
            hostname: "Hostname",
            architecture: "Arquitectura",
            processor: "Procesador",
            ram_memory: "Memoria RAM",
            system: "Sistema",
            op_system: "Sistema operativo",
            platform_release: "Release plataforma",
            platform_version: "Versión plataforma",
            network_cards: "Tarjetas de red",
            reported_incident: "Incidencia reportada",
            incident: "Incidente",
            date_time: "Fecha y hora",
            more_info: "Más información",
            contact: "Contacto",
            name_lname: "Nombre completo",
            phone_number: "Télefono",
            additional_notes: "Notas adicionales",
            connect: "Conectar",
            verify_con: "Verificar conexión",
            send_msg: "Enviar mensaje",
            disconnect: "Desconectar",
            copyright: "Copyright © ",
            invalid_email: "Correo eletrónico inválido.",
            send_dl_link_failed: "No se pudo enviar el enlace de descarga.",
            verify_con_failed: "No se pudo comunicar con la máquina remota.",
            verify_con_success: "Máquina remota conectada.",
            delete_machine_success: "Máquina eliminada correctamente.",
            edit_machine_failed: "No se pudo guardar los cambios."
        },
        dashboard: {
            processes: "Procesos",
            network: "Tráfico de red",
            devices: "Dispositivos",
            performance: "Rendimiento",
            shell: "Shell",
            send_msg: "Enviar mensaje",
            test_connection: "Test conexión",
            machine_info: "Información máquina",
            back_to_machines: "Volver al listado",
            disconnect: "Desconectar",
            copyright: "Copyright © ",
            verify_con_failed: "No se pudo comunicar con la máquina remota.",
            verify_con_success: "Máquina conectada.",
            actions: "Acciones",
            shutdown: "Apagar",
            restart: "Reiniciar",
            help: "Ayuda",
            waiting_data: "Esperando datos de la máquina remota"
        },
        processes: {
            title: "Procesos en ejecución",
            pid: "PID",
            name: "Nombre",
            status: "Estado",
            cpu: "CPU",
            cpu_af: "CPU AF.",
            priority: "Prioridad",
            memory: "Memoria",
            disk: "Disco",
            threads: "Hilos",
            creation: "Creación",
            user: "Usuario",
            path: "Ruta"
        },
        shell: {
            window: "Ventana",
            new_window: "Nueva ventana",
            active: "activa",
            inactive: "inactiva",
            current_directory: "Directorio actual",
            version: "squid-shell v1.0",
            start_help_line: "SÓLO comandos específicos de la plataforma -- Use \"help\" para más información.",
            command_help: "Listado de comandos disponibles",
            command_clear: "Limpiar el terminal",
            command_terminate: "Terminar la sesión shell remota",
            command_restart: "Reiniciar la sesión shell remota",
            command_set_timeout: "Establecer el time-out para la sesión remota (N debe ser >= 1)",
            help_version: "squid-shell v1.0 está basado en la implementación frontend vue-shell de @HalasProject's:=="
        },
        admin: {
            title: "Administración",
            back_to_main: "Volver",
            refresh: "Refrescar",
            download_codes: "Códigos de descarga",
            login_logs: "Logs de acceso",
            machine_logs: "Logs de máquina",
            account_management: "Gestión de cuentas",
            copyright: "Copyright",
            about: "Sobre squid-remote"
        },
        download_codes: {
            id: "ID",
            platform: "Platforma",
            email: "Correo",
            author: "Generado por",
            creation_date: "Creado el",
            download_date: "Descargado el",
            connection_date: "Usado el",
            not_available: "N/D",
            disable: "Desactivar",
            enable: "Activar"
        },
        login_logs_view: {
            id: "ID",
            username: "Usuario",
            date: "Fecha",
            extension_login: "Sesión extendida",
            agent_type: "Tipo de agente",
            debug_auth: "Acceso debug",
            zero: "Usuario",
            one: "Máquina",
            two: "Pusher/Otros servicios",
            yes: "Sí",
            no: "No"
        },
        machine_logs_view: {
            select_machine: "Selecciona una máquina registrada",
            id: "ID",
            username: "Usuario",
            operation: "Operación",
            date: "Fecha",
            ip: "IP",
            previous_value: "Valor anterior",
            new_value: "Nuevo valor"
        },
        accounts_management: {
            email: "Correo electrónico",
            id: "ID",
            username: "Usuario",
            first_name: "Nombre",
            last_name: "Apellidos",
            telf: "Teléfono",
            type: "Tipo",
            expiration_date: "Fecha caducidad",
            not_available: "N/D",
            machine: "Máquina",
            admin: "Administrador",
            tech: "Técnico",
            active: "Activo",
            yes: "Sí",
            no: "No",
            actions: "Acciones",
            new_account: "Crear cuenta",
            create: "Crear",
            close: "Cerrar",
            create_account_error: "No se puedo crear la cuenta. Comprueba que el nombre de usuario no esté en uso, por ejemplo.",
            new_account_details: "Detalles de la nueva cuenta",
            password: "Contraseña",
            expires: "Caduca",
            demo_account: "Cuenta de demo",
            new_account_password_advice: "Asegúrate de apuntar la contraseña, ya que esta será la primera y última vez que la podrás ver."
        },
        network_traffic: {
            emtpy_title: "¡Esto está vacío!",
            empty_comment: "¿Vamos a pescar paquetes?",
            raw: "Raw",
            captured_packets: "Paquetes capturados",
            packet_info: "Información del paquete",
            packet_filters: "Filtros de paquetes",
            capture_controls: "Controles de captura",
            id: "ID",
            ttl: "TTL",
            time: "Tiempo",
            source: "Origen",
            destination: "Destino",
            version: "Versión",
            ip_protocol: "Protocolo IP",
            src: "Orgn",
            dst: "Dstno",
            ip_wversion: "Internet Procotol Versión (IP)",
            tcp: "Transmission Control Protocol (TCP)",
            src_port: "Orgn Port",
            dst_port: "Dstno Port",
            seq: "Seq",
            ack: "Ack",
            len: "Lon",
            padding: "Padding",
            udp: "User Datagram Protocol (UDP)",
            dns: "Domain Name System (DNS)",
            opcode: "opcode",
            dns_question_records: "DNS Question Records",
            qname: "qname",
            qtype: "qtype",
            qclass: "qclass",
            icmp: "Internet Control Message Protocol (ICMP)",
            type: "Type",
            code: "Code",
            rcode: "rcode",
            qdcount: "qdcount",
            length: "Longitud",
            checksum: "Checksum",
            info: "Info",
            capture_options: "Opciones de captura del tráfico",
            capture: "Captura",
            stop: "Stop",
            save_data_json: "Guardar como JSON",
            capture_mode: "Modo de captura",
            capture_packets: "Captura por cantidad de paquetes",
            capture_packets_selected: "Número de paquetes a capturar",
            capture_time_selected: "Capturar durante (segundos)",
            capture_time: "Captura por tiempo",
            show_options: "Ver opciones",
            hide_options: "Ocultar opciones",
            reset: "Reset",
            capture_card: "Dispositivo de captura",
            select: "Seleccionar"
        },
        devices: {
            emtpy_title: "¡Esto está vacío!",
            empty_comment: "Clica en 'Recuperar' para listar los dispositivos conectados.",
            title: "Dispositivos USB conectados",
            retrieve: "Recuperar",
            last_updated: "Última actualización",
            na: "N/D",
            vendor_id: "Fabricante (ID)",
            product_id: "Producto (ID)",
            version_number: "Versión",
            device_path: "Ruta dispositivo",
            instance_id: "Instancia (ID)"
        },
        hardware_monitor: {
            system_status: "Estado del sistema",
            cpu_usage: "Carga CPU",
            current_freq: "Frec. actual",
            max_freq: "Frec. máx.",
            memory_usage: "Uso de memoria",
            in_use: "En uso",
            total: "Total",
            swap_usage: "Uso de swap",
            performance_charts: "Gráficas de rendimiento",
            system_stats: "Estadísticas del sistema",
            since_start: "Desde el arranque",
            data_written_d: "Datos escritos en discos",
            data_read_d: "Datos leídos de discos",
            bytes: "bytes",
            data_sent_n: "Datos envíados por la red",
            data_rec_n: "Datos recibidos por la red",
            cpu_load_dstr: "Distribución carga CPU",
            physical_cores: "núcleos físicos",
            logical_cores: "núcleos lógicos",
            core: "Núcleo",
            gpus: "GPUs (NVIDIA solo)",
            used_mem: "Memoria usada",
            total_mem: "Memoria total",
            temperature: "Temperatura",
            uuid: "UUID",
            disks_drives: "Discos duros",
            disk: "Disco"
        }
    },
    CA: {
        admin_help: {
            title: "Panell d'administració",
            subtitle: "Des d'aquí podràs habilitar / deshabilitar els codis de descàrrega, crear comptes i veure els logs.",
            dcodes: "Codis de descàrrega",
            dcodest: "Els codis de descàrrega actuen com a clau d'accés en una connexió de client. S'envien per correu electrònic a través de la pàgina principal. Cada codi està vinculat a un correu electrònic, que s'ha d'introduir a la màquina remota per autoritzar la connexió . Si el correu electrònic del codi de descàrrega i l'introduït no coincideixen, la connexió és rebutjada. Cada codi permet una descàrrega de client per a la plataforma donada i una connexió. En aquesta secció es mostra el registre de quan es van usar els codis. Des aquesta secció també pot activar o desactivar els privilegis de descàrrega i de connexió de cada codi.",
            logs: "Registres",
            logst: "Es mantenen dos tipus de registres: registres d'inici de sessió i registres de màquina. Els registres d'inici de sessió mantenen un registre de tots els usuaris / serveis que s'han autenticat amb l'aplicació (això inclou màquines i Pusher). el registre de màquines manté un registre de tots les accions realitzades sobre una màquina específica. ",
            accounts: "Gestió de comptes",
            accounts1: "Cada compte té una data de venciment, després de la qual l'usuari perd la capacitat d'iniciar sessió. Hi ha tres tipus de comptes:",
            admin: "Compte d'administrador",
            admint: "Rol mestre. Caduca als 1000 anys.",
            tech: "Compte de tècnic",
            techt: "Similar a l'administrador, però sense la capacitat d'administrar comptes. Caduca als 3 anys.",
            demo: "Compte demo",
            demot: "Igual que un tècnic. Caduca 2 dies després de la creació.",
            account2: "Es poden realitzar diferents accions per un compte determinat (excepte en comptes d'administrador):",
            editdata: "Edita la informació del compte i la data de venciment.",
            regenpswd: "Regenerar la contrasenya del compte (una nova enviada per correu electrònic).",
            deactacc: "Desactivar un compte.",
            reactacc: "Reactivar un compte.",
            Butto: "Aquestes accions es poden realitzar utilitzant els següents botons (que s'adjunten a cada fila):",
            editbt: "Botó editar",
            savebt: "Botó desar",
            deabt: "Botó descativar compte",
            reabt: "Botó reactivar compte",
            regbt: "Botó de regenerar contrasenya"
        },
        main_help: {
            title: "Aquesta és la pàgina principal d'squid-remote",
            subtitle: "Des d'aquesta pàgina podràs connectar-te a les màquines, enviar enllaços de descàrrega, així com administrar els codis de descàrrega, els comptes d'usuari i veure els registres.",
            navbar: "Barra de navegació",
            navbar_text: "Pots utilitzar la barra de navegació per accedir a les següents funcions:",
            sendDL: "Enviar enllaç de descàrrega.",
            refresh: "Refrescar la pàgina principal.",
            admin: "Anar al panell d'administración.",
            closeHelp: "Tancar aquesta finestra.",
            closeSession: "Tancar sesió.",
            managing_machine: "Gestió de màquines",
            machine1: "Cada màquina es mostra en un estil de targeta amb accés a tota la seva informació. La informació de sistema operatiu i del maquinari del sistema la proporciona el client i no es pot modificar. Els camps d'informació de el cas es poden modificar amb el botó d'edició i guardar amb el botó de desar. També pot eliminar la màquina fent clic al botó Elimina.",
            machine2: "Una cop una màquina ha informat a servidor de la seva connexió satisfactòria, apareixeran tots els botons d'accions. Si la màquina està fora de línia o encara no està connectada, només estarà disponible el botó 'Verificar connexió'.",
            con: "Redirecciona al taulell de la máquina.",
            test: "Test de connexió amb la màquina (ping).",
            msg: "Permet enviar un missatege a la máquina.",
            discon: "Desconnecta la màquina remota."
        },
        about: {
            author: "Desenvolupat per",
            about: "Sobre el projecte",
            t1: "squid-remote és un sistema d'anàlisi forense remot multiplataforma sobre endpoints. Dissenyat per a un ús posterior a l'incident, permet la monitorització d'informació múltiple (processos, tràfic de xarxa, connexió de dispositius, ...) així com l'ús remot de diferents eines RI (imatges de memòria, recopilació de registres, shell invers, ...). ",
            t2: "Va ser desenvolupat com el meu projecte de fi de carrera d'Enginyeria Informàtica. Consta de tres parts principals: client, servidor i interfície. La interfície està construïda en Vue.js i el client i servidor en Python (el servidor fa servir el framework Flask ). ",
            t3: "L'objectiu principal era crear una eina que pogués ser utilitzada per qualsevol persona en qualsevol lloc. Qualsevol persona amb coneixements bàsics d'ús d'un ordinador pot executar el client i permetre la intervenció remota d'un tècnic. Tan simple com això. No cal utilitzar ni VPNs ni configuració especial de sockets ni instal·lar biblioteques o requisits a la màquina remota. Un cop finalitzada la intervenció, el client pot ser eliminat i no hi haurà cap rastre a la màquina. ",
            t4: "Tota la comunicació es realitza a través de HTTPS i la transferència de dades entre la màquina i el servidor amb una base de dades de Redis, que s'utilitza com a memòria cau. La interfície es sincronitza amb el servidor i els esdeveniments de la màquina mitjançant Pusher "
        },
        dashboard_help: {
            title: "Benvingut a el panell de control de squid-remote!",
            desc: "Aquesta és la pàgina principal d'operacions. Des d'aquí podràs monitoritzar processos, capturar el tràfic de xarxa, monitoritzar el rendiment de l'equip remot a més de ejcutar comandaments en un intèrpret d'ordres remota.",
            interface: "Panell de Control",
            tl_section: "Part superior esquerra",
            tr_section: "Part superior dreta",
            processes: "Monitorització de processos.",
            network: "Captura de tràfic de xarxa.",
            devices: "Llistat de dispositius connectats.",
            monitor: "Monitor de rendiment de sistema.",
            shell: "Shell remota.",
            send_msg: "Enviar missatge a màquina remota.",
            con_test: "Test de connexió amb la màquina.",
            case_info: "Informació de la màquina i de el cas.",
            go_back: "Tornar a llistat de màquines.",
            actions_bt: "Botó d'accions (desconnectar).",
            proc_desc: "El monitoratge de processos mostrarà la següent informació de cada procés que s'estigui executant en la màquina remota:",
            process_mon: "Monitorització de processos",
            pid: "PID",
            pidt: "Identificador de procés.",
            name: "Nom",
            namet: "Nom de del procés.",
            status: "Estat",
            statust: "Estat d'execució actual (Running, Stopped).",
            cpu: "CPU",
            cput: "Utilització en % de la CPU.",
            cpu_aff: "CPU Af.",
            cpu_afft: "Quantitat de CPUs que el procés té permès utilitzar.",
            priority: "Prioritat",
            priorityt: "La prioritat que té el procés.",
            memory: "Memòria",
            memoryt: "Ús de memòria actual.",
            disk: "Disc",
            diskt: "Utilització en % de discos.",
            threads: "Fils",
            threadst: "Nombre de fils creats pel procés.",
            creació: "Creació",
            creationt: "Data i hora de creació del procés.",
            user: "Usuari",
            Usert: "Usuari que ha instanciat el procés.",
            path: "Ruta",
            patht: "Directori de el procés.",
            net_sniff: "Captura de Trànsit de Xarxa",
            net_sniff_desc: "Aquest component permet capturar el trànsit a la xarxa de la màquina remota. Té dos modes de captura: per quantitat de paquets o per temps. Es poden configurar els filtres i les condicions de captura" +
                " a través de el controlador de l'component.",
            net_sniff_desc2: "Els paquets capturats apareixeran amb tres seccions: a la taula amb tots els paquets capturats, i en un resum i amb el paquet tal qual quan es clica a un paquet de la taula. Exemple:",
            cap_pack: "Paquets capturats",
            pack_info: "Informació de paquet",
            pack_raw: "Raw",
            con_dev_ret: "Llistat de Dispositius Connectats",
            con_dev_desc: "Aquest component intentarà llistar tots els dispositius connectats al Bus Sèrie Universal. És possible que algun dispositiu aparegui més d'una vegada. Això és" +
                " pel fet que el dispositiu té més d'un botó o element connectat al bus. El següent exemple mostra la informació recuperada d'un dispositiu USB.",
            remo_shell: "Shell Remota",
            remo_shell_desc: "El nom ho diu tot. Aquest component permet executar ordres per consola a la màquina remota.",
            remo_shell_win: "Client Windows",
            remo_shell_wint: "Actua com una consola normal. Quan s'accedeix a un directori, la sessió roman en el directori si no s'indica el contrari.",
            remo_shell_lin: "Client Linux",
            remo_shell_lint: "S'han de concatenar les ordres amb canonades, ja que el backend en aquest cas no manté el directori de treball després de l'execució de cada comanda. Recorda que en Linux " +
                "s'usa el caràcter \" | \" per enviar la sortida d'una ordre a l'entrada d'un altre.",
            remo_shell_com: "La shell remota té alguns comandaments integrats:",
            rs_help: "help",
            rs_helpt: "Mostra tots els ordres disponibles.",
            rs_clear: "clear",
            rs_cleart: "Neteja totes les sortides de la pantalla per a la sessió actual.",
            rs_terminate: "terminate",
            rs_terminatet: "Acaba la shell remota actual.",
            rs_restart: "restart",
            rs_restartt: "Reincia la shell remota (en cas que s'hagi acabat abans o hagi caigut).",
            rs_settm: "set-timeout",
            rs_settmt: "Estableix el temps màxim que el rerefons esperarà a la sortida de la comanda (si una ordre dóna timeout, s'haurà d'incrementar el timeout)."
        },
        login: {
            username: "Usuari",
            password: "Contrasenya",
            sign_in: "Accedir",
            req_demo: "Vols saber més? Demana una demo!",
            copyright: "Copyright © ",
            first_name: "Nom",
            last_name: "Cognoms",
            email: "Correu electrònic",
            demo_reason: "Per què vols una demo?",
            request: "Demanar",
            return: "Tornar",
            login_failed: "Usuari o contrasenya incorrectes."
        },
        main: {
            help: "Ajuda",
            chelp: "Tancar ajuda",
            registered_machines: "Màquines registrades",
            send_dl_link: "Enviar enllaç de descàrrega",
            refresh: "Refrescar",
            administration: "Administració",
            log_out: "Tancar sessió",
            email: "Correu electrònic",
            language: "Idioma",
            select: "Selecciona",
            spanish: "Castellà",
            english: "Anglés",
            catalan: "Català",
            platform: "Platforma",
            windows: "Windows x64",
            linux: "Linux x64",
            close: "Tancar",
            send: "Enviar",
            send_msg_machine: "Enviar missatge a màquina",
            hostname: "Hostname",
            architecture: "Arquitectura",
            processor: "Processador",
            ram_memory: "Memòria RAM",
            system: "Sistema",
            op_system: "Sistema operatiu",
            platform_release: "Release plataforma",
            platform_version: "Versió plataforma",
            network_cards: "Targetes de xarxa",
            reported_incident: "Incidència reportada",
            incident: "Incident",
            date_time: "Data i hora",
            more_info: "Més informació",
            contact: "Contacte",
            name_lname: "Nom complet",
            phone_number: "Telèfon",
            additional_notes: "Notes addicionals",
            connect: "Conectar",
            verify_con: "Verificar connexió",
            send_msg: "Enviar missatge",
            disconnect: "Desconectar",
            copyright: "Copyright © ",
            invalid_email: "Adreça de correu electrònic invàlida.",
            send_dl_link_failed: "No s'ha pogut enviar l'enllaç de descàrrega.",
            verify_con_failed: "No s'ha pogut comunicar amb la màquina remota.",
            verify_con_success: "La màquina remota és connectada.",
            delete_machine_success: "Màquina eliminada correctament.",
            edit_machine_failed: "No s'han pogut guardar els canvis."
        },
        dashboard: {
            processes: "Processos",
            network: "Trànsit de xarxa",
            devices: "Dispositius",
            performance: "Rendiment",
            shell: "Shell",
            send_msg: "Enviar misatge",
            test_connection: "Test connexió",
            machine_info: "Informació màquina",
            back_to_machines: "Tornar al llistat",
            disconnect: "Desconnectar",
            copyright: "Copyright © ",
            verify_con_failed: "No s'ha pogut comunicar amb la màquina remota.",
            verify_con_success: "Màquina conectada.",
            actions: "Accions",
            shutdown: "Apagar",
            restart: "Reiniciar",
            help: "Ajuda",
            waiting_data: "Esperant dades de la màquina remota"
        },
        processes: {
            title: "Processos en execució",
            pid: "PID",
            name: "Nom",
            status: "Estat",
            cpu: "CPU",
            cpu_af: "CPU AF.",
            priority: "Prioritat",
            memory: "Memòria",
            disk: "Disc",
            threads: "Fils",
            creation: "Creació",
            user: "Usuari",
            path: "Ruta"
        },
        shell: {
            window: "Finestra",
            new_window: "Nova finestra",
            active: "activa",
            inactive: "inactiva",
            current_directory: "Directori actual",
            version: "squid-shell v1.0",
            start_help_line: "NOMÉS comandes específiques de la plataforma -- Faci servir \"help\" per a més informació.",
            command_help: "Llistat de comandes disponibles",
            command_clear: "Netejar el terminal",
            command_terminate: "Acabar la sessió shell remota",
            command_restart: "Reiniciar la sessió shell remota",
            command_set_timeout: "Establir el time-out per a la sesió remota (N ha de ser >= 1)",
            help_version: "squid-shell v1.0 està basat en la implementació frontend vue-shell de @HalasProject's:=="
        },
        admin: {
            title: "Administració",
            back_to_main: "Tornar",
            refresh: "Refrescar",
            download_codes: "Codis de descàrrega",
            login_logs: "Logs d'accés",
            machine_logs: "Logs de màquina",
            account_management: "Gestió de comptes",
            copyright: "Copyright",
            about: "Sobre squid-remote"
        },
        download_codes: {
            id: "ID",
            platform: "Platforma",
            email: "Correu",
            author: "Generat por",
            creation_date: "Creat el",
            download_date: "Descarregat el",
            connection_date: "Usat el",
            not_available: "N/D",
            disable: "Desactivar",
            enable: "Activar"
        },
        login_logs_view: {
            id: "ID",
            username: "Usuari",
            date: "Data",
            extension_login: "Sessió allargada",
            agent_type: "Tipus d'agent",
            debug_auth: "Accés debug",
            zero: "Usuari",
            one: "Màquina",
            two: "Pusher/Altres serveis",
            yes: "Si",
            no: "No"
        },
        machine_logs_view: {
            select_machine: "Selecciona una màquina registrada",
            id: "ID",
            username: "Usuari",
            operation: "Operació",
            date: "Data",
            ip: "IP",
            previous_value: "Valor anterior",
            new_value: "Nou valor"
        },
        accounts_management: {
            email: "Correu electrònic",
            id: "ID",
            username: "Usuari",
            first_name: "Nom",
            last_name: "Cognoms",
            telf: "Telefón",
            type: "Tipus",
            expiration_date: "Fecha caducitat",
            not_available: "N/D",
            machine: "Màquina",
            admin: "Administrador",
            tech: "Técnic",
            active: "Activa",
            yes: "Si",
            no: "No",
            actions: "Accions",
            new_account: "Crear compte",
            create: "Crear",
            close: "Tancar",
            create_account_error: "No s'ha pogut crear el compte. Comprova que el nom d'usuari no estigui en ús, per exemple.",
            new_account_details: "Detalls del nou compte",
            password: "Contrasenya",
            expires: "Caduca",
            demo_account: "Compte de demo",
            new_account_password_advice: "Asegura't que anotes la contrasenya, doncs aquest serà el primer i l'últim cop que la podràs veure."
        },
        network_traffic: {
            raw: "Raw",
            emtpy_title: "Això està buit!",
            empty_comment: "Anem a pescar paquets?",
            captured_packets: "Paquets capturats",
            packet_info: "Informació del paquet",
            packet_filters: "Filtres de paquets",
            capture_controls: "Controls de captura",
            id: "ID",
            ttl: "TTL",
            time: "Temps",
            source: "Origen",
            destination: "Destí",
            version: "Versió",
            ip_protocol: "Protocol IP",
            src: "Orgn",
            dst: "Dstí",
            ip_wversion: "Internet Procotol Versió (IP)",
            tcp: "Transmission Control Protocol (TCP)",
            src_port: "Orgn Port",
            dst_port: "Dstí Port",
            seq: "Seq",
            ack: "Ack",
            len: "Lon",
            padding: "Padding",
            udp: "User Datagram Protocol (UDP)",
            dns: "Domain Name System (DNS)",
            opcode: "opcode",
            dns_question_records: "DNS Question Records",
            qname: "qname",
            qtype: "qtype",
            qclass: "qclass",
            icmp: "Internet Control Message Protocol (ICMP)",
            type: "Type",
            code: "Code",
            rcode: "rcode",
            qdcount: "qdcount",
            length: "Longitud",
            checksum: "Checksum",
            info: "Info",
            capture_options: "Opcions de captura del tràfic",
            capture: "Captura",
            stop: "Stop",
            save_data_json: "Guardar com JSON",
            capture_mode: "Mode de captura",
            capture_packets: "Captura per quantitat de paquets",
            capture_packets_selected: "Nombre de paquets a capturar",
            capture_time_selected: "Capturar durante (segons)",
            capture_time: "Captura per temps",
            show_options: "Veure opcions",
            hide_options: "Amagar opcions",
            reset: "Reset",
            capture_card: "Dispositiu de captura",
            select: "Seleccionar"
        },
        devices: {
            title: "Dispositius USB connectats",
            retrieve: "Recuperar",
            last_updated: "Darrera actualizació",
            na: "N/D",
            vendor_id: "Fabricant (ID)",
            product_id: "Producte (ID)",
            version_number: "Versió",
            device_path: "Ruta dispositiu",
            instance_id: "Instància (ID)",
            emtpy_title: "Això està buit!",
            empty_comment: "Clica en 'Recuperar' per a llistar els dipositius.",
        },
        hardware_monitor: {
            system_status: "Estat del sistema",
            cpu_usage: "Càrrega CPU",
            current_freq: "Freq. actual",
            max_freq: "Freq. màx.",
            memory_usage: "Ús de memòria",
            in_use: "En ús",
            total: "Total",
            swap_usage: "Ús de swap",
            performance_charts: "Gràfiques de rendiment",
            system_stats: "Estadístiques del sistema",
            since_start: "Desde l'inici",
            data_written_d: "Dades escrites en discs",
            data_read_d: "Dades llegides de discs",
            bytes: "bytes",
            data_sent_n: "Dades enviades por la xarxa",
            data_rec_n: "Dades rebudes por la xarxa",
            cpu_load_dstr: "Distribució càrrega CPU",
            physical_cores: "nuclis físics",
            logical_cores: "nuclis lògics",
            core: "Nucli",
            gpus: "GPUs (NVIDIA només)",
            used_mem: "Memòria usada",
            total_mem: "Memòria total",
            temperature: "Temperatura",
            uuid: "UUID",
            disks_drives: "Discs durs",
            disk: "Disc"
        }
    }
}