<style>
@import url("../assets/animate.min.css");
@import url("../assets/toastr.css");
@import url("../assets/flags.css");
</style>
<template>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css"
        integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css">
  <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet">
  <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet' type='text/css'>
  <div style="height: 100%; margin-bottom: 0; overflow: hidden; overflow-x: hidden">
    <body style="height: inherit">
    <form class="form-signin animate__animated animate__fadeIn" v-if="!demoreq">
      <div class="text-center mb-4">
        <img class="logo" src="https://leroderic.github.io/squid_remote/media/logo_big.png"
             style="height: 10em!important">
      </div>

      <div class="form-label-group">
        <input type="text" id="inputUsername" class="form-control" placeholder="Email address" required autofocus
               style="padding-left: 0.5em">
        <label style="margin-left: 0.5em">{{ $t('login.username') }}</label>
      </div>

      <div class="form-label-group">
        <input type="password" id="inputPassword" class="form-control" placeholder="Password" required
               style="padding-left: 0.5em">
        <label for="inputPassword" style="margin-left: 0.5em">{{ $t('login.password') }}</label>
      </div>
      <button class="btn btn-lg btn-primary btn-block" style="background-color: #0983a3" @click="login" type="button">
        {{ $t('login.sign_in') }}
      </button>
      <div class="text-center mb-4" style="">
        <h6 style="margin-top: 0.5em"><a href="javascript:void(0)" @click="demoreq = !demoreq"
                                         style="color: #64849c !important;">{{ $t('login.req_demo') }}</a>
        </h6>
      </div>
      <div class="dropdown text-center">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" style="background-color: transparent !important; border: none">
          <span class="flag flag-es" v-if="lang == 'ES'"></span>
          <span class="flag flag-gb" v-if="lang == 'EN'"></span>
          <span class="flag flag-catalonia" v-if="lang == 'CA'"></span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="min-width: inherit">
          <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'EN'"
             @click="updateLang('EN')"><span
              class="flag flag-gb" style="text-align: center"></span></a>
          <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'ES'"
             @click="updateLang('ES')"><span
              class="flag flag-es" style="text-align: center"></span></a>
          <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'CA'"
             @click="updateLang('CA')"><span
              class="flag flag-catalonia" style="text-align: center"></span></a>
        </div>
      </div>
      <p class="mt-5 mb-3 text-muted text-center">{{ $t('login.copyright') }} {{ getYear() }} <span
          style="font-family: 'logo_font'">squid</span></p>
    </form>
    <form class="form-signin animate__animated animate__fadeIn" v-if="demoreq" style="overflow-x: hidden">
      <div class="text-center mb-4" style="margin-top: 5.5em">
        <img class="logo" src="https://leroderic.github.io/squid_remote/media/logo_big.png"
             style="height: 10em!important">
      </div>
      <div class="form-label-group">
        <input type="text" id="dr-fname" class="form-control" placeholder="Email address" required autofocus
               style="padding-left: 0.5em">
        <label style="margin-left: 0.5em">{{ $t('login.first_name') }}</label>
      </div>
      <div class="form-label-group">
        <input type="text" id="dr-lname" class="form-control" placeholder="Email address" required autofocus
               style="padding-left: 0.5em">
        <label style="margin-left: 0.5em">{{ $t('login.last_name') }}</label>
      </div>
      <div class="form-label-group">
        <input type="email" id="dr-email" class="form-control" placeholder="Password" required
               style="padding-left: 0.5em">
        <label for="inputPassword" style="margin-left: 0.5em">{{ $t('login.email') }}</label>
      </div>
      <div class="form-label-group">
        <label for="dr-motive" style="margin-left: 0.5em; margin-bottom: 5em">{{ $t('login.demo_reason') }}<br></label>
        <textarea type="text" id="dr-motive" class="form-control" rows="3" required
                  style="padding-left: 0.5em">&#10;
          </textarea>
      </div>
      <button class="btn btn-lg btn-primary btn-block" style="background-color: #0983a3" type="button"
              @click="requestDemo">
        {{ $t('login.request') }}
      </button>
      <div class="text-center mb-4" style="">
        <h6 style="margin-top: 0.5em"><a href="javascript:void(0)" @click="demoreq = !demoreq"
                                         style="color: #64849c !important;">{{ $t('login.return') }}</a>
        </h6>
      </div>
      <div class="dropdown text-center">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" style="background-color: transparent !important; border: none">
          <span class="flag flag-es" v-if="lang == 'ES'"></span>
          <span class="flag flag-gb" v-if="lang == 'EN'"></span>
          <span class="flag flag-catalonia" v-if="lang == 'CA'"></span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="min-width: inherit">
          <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'EN'"
             @click="updateLang('EN')"><span
              class="flag flag-gb" style="text-align: center"></span></a>
          <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'ES'"
             @click="updateLang('ES')"><span
              class="flag flag-es" style="text-align: center"></span></a>
          <a class="dropdown-item" style="text-align: center" href="javascript:void(0)" v-if="lang != 'CA'"
             @click="updateLang('CA')"><span
              class="flag flag-catalonia" style="text-align: center"></span></a>
        </div>
      </div>
      <p class="mt-5 mb-3 text-muted text-center">{{$t("login.copyright")}} {{ getYear() }} <span
          style="font-family: 'logo_font'">squid</span></p>
    </form>
    </body>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {bus, api, SECURE_COOKIES} from '../main.js'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import * as toastr from "@/assets/toastr";

export default {
  name: "Login",
  props: {
    lang: String,
  },
  data() {
    return {
      username: "",
      password: "",
      demoreq: false,
      dLang: ""
    }
  },
  created() {
    if (this.$cookie.isCookieAvailable("srld")) {
      this.$router.push('/app')
    }
  },
  methods: {
    updateLang(l) {
      // eslint-disable-next-line vue/no-mutating-props
      bus.emit('lang-updated', {
        'lang': l,
      })
    },
    saveLogInDataCookie(data) {
      this.$cookie.setCookie("srld", data, {
        expire: '2h',
        path: '/',
        domain: '',
        secure: true, //true
        sameSite: '',
      })
    }
    ,
    login() {
      const path = api + 'auth'
      axios.post(path, {
        'username': document.getElementById("inputUsername").value,
        'password': document.getElementById("inputPassword").value
        // eslint-disable-next-line no-unused-vars
      }).then((res) => {
        this.$router.push('/app')
        this.saveLogInDataCookie({
          token: String(res.data.token.toString()), type: parseInt(res.data.type),
          id: res.data.id
        })
        bus.emit('has-logged-in', {
          'token': String(res.data.token.toString()),
          'type': parseInt(res.data.type),
          'id': res.data.id
        })
      }).catch((error) => {
        // eslint-disable-next-line
        console.error(error)
        toastr.error('', this.$t('login.login_failed'),
            {
              timeOut: 1500,
              progressBar: true,
              newestOnTop: true,
              positionClass: 'toast-bottom-right',
              preventDuplicates: true
            })
      })
    },
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(document.getElementById("dr-email").value.toLowerCase()))
    },
    requestDemo() {
      if (this.validateEmail() &&
          document.getElementById("dr-fname").value != "" &&
          document.getElementById("dr-lname").value != "" &&
          document.getElementById("dr-motive").value != ""
      ) {
        const path = api + 'demo_request'
        this.demoreq = !this.demoreq
        axios.post(path, {
          'fname': document.getElementById("dr-fname").value,
          'lname': document.getElementById("dr-lname").value,
          'email': document.getElementById("dr-email").value,
          'reason': document.getElementById("dr-motive").value
          // eslint-disable-next-line no-unused-vars
        }).then((res) => {
        })
      }
    },
    getYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
@font-face {
  src: url("../assets/namo.ttf");
  font-family: "logo_font";
}

@media (max-width: 480px) {

  .logo {
    max-height: 8.8em !important;
  }

  body {
    overflow: hidden;
  }
}

html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background: linear-gradient(-45deg, #022B3A, #191C1E, #1F7A8C);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: 0 auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group textarea:not(:placeholder-shown) {
  padding-top: 1em;
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group textarea:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}
</style>