import {createWebHistory, createRouter} from 'vue-router';
import Login from "@/components/Login";
import Main from "@/components/Main";
import MachineDashBoard from "@/components/MachineDashBoard";
import Admin from "@/components/Admin";
const routes = [
    {
        path: "/",
        name: "Auth",
        component: Login,
    },
    {
        path: "/app",
        name: "Main",
        component: Main,
    },
    {
        path: "/machine/:id",
        name: "MachineDashboard",
        component: MachineDashBoard
    },
    {
        path: "/admin",
        name: "Administration",
        component: Admin
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;