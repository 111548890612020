<template>
  <div>
    <div class="container animate__animated animate__fadeInUp" style="max-width: 1880px; margin-top: 1.5em">
      <div class="card">
        <div class="card-body">
          <h2>{{ $t('dashboard_help.title') }}</h2>
          <p class="card-text" style="font-size: 1.5em">{{ $t('dashboard_help.desc') }}</p>
          <!-- navbar help -->
          <div>
            <h3>{{ $t('dashboard_help.interface') }}</h3>
            <div class="card">
              <nav class="navbar navbar-expand-md navbar-dark bg-dark"
                   style="background-color: #191c1e !important"
                   id="dashboard-nav1">
        <span class="navbar-nav mr-auto">
          <img class="navbar-brand"
               src="https://leroderic.github.io/squid_remote/media/favicon.png"
               style="height: 45px; margin: 0">
          </span>
                <div class="w-100 order-1 order-md-0">
                  <ul class="navbar-nav mx-auto">
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0)" v-if="selected_mode != 0"
                         style="text-align: center">{{ $t('dashboard.processes') }}</a>
                      <a class="nav-link active" href="javascript:void(0)" v-if="selected_mode == 0"
                         style="text-align: center; color: #0c81a1"><b>{{ $t('dashboard.processes') }}</b></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0)"
                         style="text-align: center">{{ $t('dashboard.network') }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0)"
                         style="text-align: center">{{ $t('dashboard.devices') }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0)"
                         style="text-align: center">{{ $t('dashboard.performance') }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0)"
                         style="text-align: center">{{ $t('dashboard.shell') }}</a>
                    </li>
                  </ul>
                </div>
                <div class="mx-auto order-0">
                  <a class="navbar-brand ml-auto">00:00:00:00:00:00</a>
                  <button class="navbar-toggler" type="button"
                          style="margin-right: -1.5em">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                </div>
                <div class="w-100 order-3">
                  <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0)"
                         style="text-align: center">{{ $t('dashboard.send_msg') }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0)"
                         style="text-align: center">{{ $t('dashboard.test_connection') }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" style="text-align: center">{{ $t('dashboard.machine_info') }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0)"
                         style="text-align: center">{{ $t('dashboard.back_to_machines') }}</a>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                         data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false" style="text-align: center">
                        {{ $t('dashboard.actions') }}
                      </a>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="javascript:void(0)"
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-align: center">
                        <span class="flag flag-es" v-if="lang == 'ES'"></span>
                        <span class="flag flag-gb" v-if="lang == 'EN'"></span>
                        <span class="flag flag-catalonia" v-if="lang == 'CA'"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div class="row row-cols-1 row-cols-sm-2" style="margin-top: 1em; font-size: 1.5em">
              <div class="col">
                <h4>{{ $t('dashboard_help.tl_section') }}</h4>
                <li>{{ $t('dashboard_help.processes') }}</li>
                <li>{{ $t('dashboard_help.network') }}</li>
                <li>{{ $t('dashboard_help.devices') }}</li>
                <li>{{ $t('dashboard_help.monitor') }}</li>
                <li>{{ $t('dashboard_help.shell') }}</li>
              </div>
              <div class="col">
                <h4>{{ $t('dashboard_help.tr_section') }}</h4>
                <li>{{ $t('dashboard_help.send_msg') }}</li>
                <li>{{ $t('dashboard_help.con_test') }}</li>
                <li>{{ $t('dashboard_help.case_info') }}</li>
                <li>{{ $t('dashboard_help.go_back') }}</li>
                <li>{{ $t('dashboard_help.actions_bt') }}</li>
              </div>
            </div>
          </div>
          <!-- processes help -->
          <div style="margin-top: 1em">
            <h3>{{ $t('dashboard_help.process_mon') }}</h3>
            <p class="card-text" style="font-size: 1.5em">{{ $t('dashboard_help.proc_desc') }}</p>
            <div>
              <div class="table-responsive">
                <table class="table table-sm table-dark table-hover">
                  <thead>
                  <tr>
                    <th scope="col"><h4>{{ $t('processes.pid') }}</h4></th>
                    <th scope="col"><h4>{{ $t('processes.name') }}</h4></th>
                    <th scope="col"><h4>{{ $t('processes.status') }}</h4></th>
                    <th scope="col"><h4>{{ $t('processes.cpu') }}</h4></th>
                    <th scope="col" style="min-width: 8em"><h4>{{ $t('processes.cpu_af') }}</h4></th>
                    <th scope="col"><h4>{{ $t('processes.priority') }}</h4></th>
                    <th scope="col"><h4>{{ $t('processes.memory') }}</h4></th>
                    <th scope="col"><h4>{{ $t('processes.disk') }}</h4></th>
                    <th scope="col"><h4>{{ $t('processes.threads') }}</h4></th>
                    <th scope="col" style="min-width: 10em"><h4>{{ $t('processes.creation') }}</h4></th>
                    <th scope="col" style="min-width: 12em"><h4>{{ $t('processes.user') }}</h4></th>
                    <th scope="col" style="max-width: 15em"><h4>{{ $t('processes.path') }}</h4></th>
                  </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div class="row row-cols-1 row-cols-sm-2" style="font-size: 1.5em">
              <div class="col">
                <li><b>{{ $t('dashboard_help.pid') }}</b>: {{ $t('dashboard_help.pidt') }}</li>
                <li><b>{{ $t('dashboard_help.name') }}</b>: {{ $t('dashboard_help.namet') }}</li>
                <li><b>{{ $t('dashboard_help.status') }}</b>: {{ $t('dashboard_help.statust') }}</li>
                <li><b>{{ $t('dashboard_help.cpu') }}</b>: {{ $t('dashboard_help.cput') }}</li>
                <li><b>{{ $t('dashboard_help.cpu_aff') }}</b>: {{ $t('dashboard_help.cpu_afft') }}</li>
                <li><b>{{ $t('dashboard_help.priority') }}</b>: {{ $t('dashboard_help.priorityt') }}</li>
              </div>
              <div class="col">
                <li><b>{{ $t('dashboard_help.memory') }}</b>: {{ $t('dashboard_help.memoryt') }}</li>
                <li><b>{{ $t('dashboard_help.disk') }}</b>: {{ $t('dashboard_help.diskt') }}</li>
                <li><b>{{ $t('dashboard_help.threads') }}</b>: {{ $t('dashboard_help.threadst') }}</li>
                <li><b>{{ $t('dashboard_help.creation') }}</b>: {{ $t('dashboard_help.creationt') }}</li>
                <li><b>{{ $t('dashboard_help.user') }}</b>: {{ $t('dashboard_help.usert') }}</li>
                <li><b>{{ $t('dashboard_help.path') }}</b>: {{ $t('dashboard_help.patht') }}</li>
              </div>
            </div>
          </div>
          <!-- network traffic capture -->
          <div style="margin-top: 1em">
            <h3>{{ $t('dashboard_help.net_sniff') }}</h3>
            <p class="card-text" style="font-size: 1.5em">{{ $t('dashboard_help.net_sniff_desc') }}</p>
            <div class="card-header">
              <div class="row row-cols-1 row-cols-md-5">
                <div class="col">
                  <h6>{{ $t('network_traffic.capture_controls') }}:</h6>
                  <button type="button" class="btn btn-primary"
                          style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em">
                    {{ $t('network_traffic.capture') }}
                  </button>
                  <button type="button" class="btn btn-warning"
                          style="margin-right: 0.5em; margin-bottom: 0.5em">
                    {{ $t('network_traffic.save_data_json') }}
                  </button>
                  <button type="button" class="btn btn-outline-danger" style="margin-right: 0.5em; margin-bottom: 0.5em"
                  >{{ $t('network_traffic.reset') }}
                  </button>
                </div>
                <div class="col">
                  <h6>{{ $t('network_traffic.packet_filters') }}:</h6>
                  <button type="button" class="btn btn-primary"
                          style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em">
                    TCP
                  </button>
                  <button type="button" class="btn btn-primary"
                          style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em">
                    ICMP
                  </button>

                  <button type="button" class="btn btn-primary"
                          style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em">
                    DNS
                  </button>
                  <button type="button" class="btn btn-primary"
                          style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em">
                    UDP
                  </button>
                </div>
                <div class="col">
                  <h6>{{ $t('network_traffic.capture_card') }}:</h6>
                  <select class="form-control" v-model="captureDevice">
                    <option value=-1 disabled>{{ $t('network_traffic.select') }}</option>
                    <option v-for="(d, index) in captureDevices" :value="index" :key="index">{{ d }}</option>
                  </select>
                </div>
                <div class="col">
                  <h6>{{ $t('network_traffic.capture_mode') }}:</h6>
                  <select class="form-control" v-model="captureMode">
                    <option value=0>{{ $t('network_traffic.capture_packets') }}</option>
                    <option value=1>{{ $t('network_traffic.capture_time') }}</option>
                  </select>
                </div>
                <div class="col">
                  <div v-if="captureMode == 0">
                    <h6>{{ $t('network_traffic.capture_packets_selected') }}:</h6>
                    <input type="number" class="form-control">
                  </div>
                  <div v-if="captureMode == 1">
                    <h6>{{ $t('network_traffic.capture_time_selected') }}:</h6>
                    <input type="number" class="form-control">
                  </div>
                </div>
              </div>
            </div>
            <p class="card-text" style="font-size: 1.5em">{{ $t('dashboard_help.net_sniff_desc2') }}</p>
            <div>
              <h5>{{ $t('network_traffic.captured_packets') }}</h5>
              <div class="table-responsive" style="max-height: 20em; background-color: #383c44; border-radius: 5px">
                <table class="table table-sm table-dark table-hover table-fixed">
                  <thead>
                  <tr>
                    <th scope="col"><h4>{{ $t('network_traffic.id') }}</h4></th>
                    <th scope="col"><h4>{{ $t('network_traffic.time') }}</h4></th>
                    <th scope="col"><h4>{{ $t('network_traffic.source') }}</h4></th>
                    <th scope="col"><h4>{{ $t('network_traffic.destination') }}</h4></th>
                    <th scope="col" style="min-width: 8em"><h4>{{ $t('network_traffic.length') }}</h4></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>0</td>
                    <td>1619979861.437799</td>
                    <td>192.168.1.151</td>
                    <td>192.168.1.255</td>
                    <td>216</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <h5 style="margin-top: 0.5em">{{ $t('network_traffic.packet_info') }}</h5>
              <div class="card-body"
                   style="max-height: 15em; background-color: #383c44; color: white; font-family: Consolas; overflow: auto; border-radius: 5px">
                <ul class="fa-ul">
                  <li><span class="fa-li"><i class="fas fa-caret-right"
                  ></i></span>
                    4c:72:b9:d5:1a:0f -&gt; ff:ff:ff:ff:ff:ff
                  </li>
                  <li><span class="fa-li" data-v-296618eb=""><i class="fas fa-caret-right"
                  ></i></span>
                    {{ $t('network_traffic.ip_wversion') }} 4, {{ $t('network_traffic.src') }}: 192.168.1.151,
                    {{ $t('network_traffic.dst') }}: 192.168.1.255
                  </li>
                  <li><span class="fa-li"><i class="fas fa-caret-right"
                  ></i></span>
                    {{ $t('network_traffic.udp') }}, {{ $t('network_traffic.src_port') }}: 138,
                    {{ $t('network_traffic.checksum') }}: 45380, {{ $t('network_traffic.len') }}: 182
                  </li>
                </ul>
              </div>
              <h5 style="margin-top: 0.5em">{{ $t('network_traffic.raw') }}</h5>
              <div
                  style="max-height: 15em; background-color: #373A3E; color: white; font-family: Consolas; overflow: auto; resize: vertical; border-radius: 5px">
                \xff\xff\xff\xff\xff\xffLr\xb9\xd5\x1a\x0f\x08\x00E\x00\x00\xca\x93\xa2\x00\x00\x80\x11!\x9a\xc0\xa8\x01\x97\xc0\xa8\x01\xff\x00\x8a\x00\x8a\x00\xb6\xb1D\x11\x02\xd6\xd5\xc0\xa8\x01\x97\x00\x8a\x00\xa0\x00\x00
                FCEPEJEMENEBCNFAEDCACACACACACAAA\x00
                FHEPFCELEHFCEPFFFACACACACACACABN\x00\xffSMB%\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x11\x00\x00\x06\x00\x00\x00\x00\x00\x00\x00\x00\x00\xe8\x03\x00\x00\x00\x00\x00\x00\x00\x00\x06\x00V\x00\x03\x00\x01\x00\x01\x00\x02\x00\x17\x00\\MAILSLOT\\BROWSE\x00\t\x04\x0e\x00\x00\x00
              </div>
            </div>
          </div>
          <!-- connected devices retrieval -->
          <div style="margin-top: 1em">
            <h3>{{ $t('dashboard_help.con_dev_ret') }}</h3>
            <p class="card-text" style="font-size: 1.5em">{{ $t('dashboard_help.con_dev_desc') }}</p>
            <div>
              <div class="card h-100" style="width: 18rem;">
                <div class="card-body">
                  <h5 class="card-title">PRO X Wireless Gaming Headset</h5>
                  <h6 class="card-subtitle mb-2 text-muted" style="color: #0983a3 !important;">Logitech</h6>
                  <ul>
                    <li>{{ $t('devices.vendor_id') }}: 1133</li>
                    <li>{{ $t('devices.product_id') }}: 2746</li>
                    <li>{{ $t('devices.version_number') }}: 256</li>
                    <li>{{ $t('devices.device_path') }}: \\?\hid#vid_046d&pid_0aba&mi_03&col01#8&5DCCB498&0&0220</li>
                    <li>{{ $t('devices.instance_id') }}: HID\VID_046D&PID_0ABA&MI_03&COL01\8&5DCCB498&0&0220</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- remote shell component -->
          <div style="margin-top: 1em">
            <h3>{{ $t('dashboard_help.remo_shell') }}</h3>
            <p class="card-text" style="font-size: 1.5em">{{ $t('dashboard_help.remo_shell_desc') }}</p>
            <p class="card-text" style="font-size: 1.5em">{{ $t('dashboard_help.remo_shell_com') }}</p>
            <span style="font-size: 1.5em">
            <li><b>{{ $t('dashboard_help.rs_help') }}</b>: {{ $t('dashboard_help.rs_helpt') }}</li>
            <li><b>{{ $t('dashboard_help.rs_clear') }}</b>: {{ $t('dashboard_help.rs_cleart') }}</li>
            <li><b>{{ $t('dashboard_help.rs_terminate') }}</b>: {{ $t('dashboard_help.rs_terminatet') }}</li>
            <li><b>{{ $t('dashboard_help.rs_restart') }}</b>: {{ $t('dashboard_help.rs_restartt') }}</li>
            <li><b>{{ $t('dashboard_help.rs_settm') }}</b>: {{ $t('dashboard_help.rs_settmt') }}
            </li>
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StartupScreen",
  props: {
    lang: String
  },
  data() {
    return {
      captureMode: 0
    }
  }
}
</script>

<style scoped>
</style>