<template>
  <div class="container" style="max-width: 1880px">
    <div class="row row-cols-1 row-cols-md-2" style="margin-top: 1em">
      <div class="col">
        <h3>{{ $t('about.author') }}
          <h5 style="color: #0983a3">Rodrigo Cabezas Quirós</h5>
          <a rel="noopener noreferrer" class="btn btn-secondary" href="https://www.github.com/leroderic"
             target="_blank" style="background-color: #24292e"><i class="fab fa-github"
                                                                  style="font-size: 2em"></i></a>&nbsp;
          <a rel="noopener noreferrer" class="btn btn-secondary" href="https://www.linkedin.com/in/rcq"
             target="_blank" style="background-color: #0E76A8"><i class="fab fa-linkedin"
                                                                  style="font-size: 2em"></i></a>&nbsp;
          <a rel="noopener noreferrer" class="btn btn-secondary" href="mailto:rcq@rcq.es"
             style="background-color: #102f49" target="_blank"><i class="fas fa-envelope"
                                                                  style="font-size: 2em"></i></a>
        </h3>
      </div>
      <div class="col">
        <h3 style="margin-top: 0.5em">{{ $t('about.about') }}</h3>
        <p style="color: #0983a3"><b>{{ $t('about.t1') }}</b></p>
        <p style="color: #0983a3"><b>{{ $t('about.t2') }}</b></p>
        <p style="color: #0983a3"><b>{{ $t('about.t3') }}</b></p>
        <p style="color: #0983a3"><b>{{ $t('about.t4') }}</b></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutSquidRemote"
}
</script>

<style scoped>

</style>