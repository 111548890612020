<template>
  <div>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css">
    <div class="container animate__animated animate__fadeInUp"
         style="max-width: 1880px; margin-top: 1.5em; margin-bottom: 1em">
      <div class="card">
        <div class="card-header">
          <div class="row row-cols-1 row-cols-md-5">
            <div class="col">
              <h6>{{ $t('network_traffic.capture_controls') }}:</h6>
              <button type="button" class="btn btn-primary"
                      style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em"
                      :disabled="recording || !tcpc && !dnsc && !icmpc && !udpc" @click="setCaptureOrder"
                      v-if="traffic.length > 0 && !recording">
                {{ $t('network_traffic.capture') }}
              </button>
              <button type="button" class="btn btn-primary animate__animated animate__headShake animate__infinite"
                      style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em"
                      :disabled="recording || !tcpc && !dnsc && !icmpc && !udpc" @click="setCaptureOrder" v-else>
                {{ $t('network_traffic.capture') }}
              </button>
              <button type="button" class="btn btn-warning" :disabled="traffic.length == 0"
                      style="margin-right: 0.5em; margin-bottom: 0.5em" @click="saveDataAsJson">
                {{ $t('network_traffic.save_data_json') }}
              </button>
              <button type="button" class="btn btn-outline-danger" style="margin-right: 0.5em; margin-bottom: 0.5em"
                      @click="resetCaptureOptions">{{ $t('network_traffic.reset') }}
              </button>
            </div>
            <div class="col">
              <h6>{{ $t('network_traffic.packet_filters') }}:</h6>
              <button type="button" class="btn btn-primary"
                      style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em"
                      :disabled="recording" v-if="!tcpc" @click="tcpc = true">
                TCP
              </button>
              <button type="button" class="btn btn-primary"
                      style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em; border-color: black; border-width: 0.15em"
                      :disabled="recording" v-if="tcpc" @click="tcpc = false">
                TCP
              </button>
              <button type="button" class="btn btn-primary"
                      style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em"
                      :disabled="recording" v-if="!icmpc" @click="icmpc = true">
                ICMP
              </button>
              <button type="button" class="btn btn-primary"
                      style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em; border-color: black; border-width: 0.15em"
                      :disabled="recording" v-if="icmpc" @click="icmpc = false">
                ICMP
              </button>
              <button type="button" class="btn btn-primary"
                      style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em"
                      :disabled="recording" v-if="!dnsc" @click="dnsc = true">
                DNS
              </button>
              <button type="button" class="btn btn-primary"
                      style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em; border-color: black; border-width: 0.15em"
                      :disabled="recording" v-if="dnsc" @click="dnsc = false">
                DNS
              </button>
              <button type="button" class="btn btn-primary"
                      style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em"
                      :disabled="recording" v-if="!udpc" @click="udpc = true">
                UDP
              </button>
              <button type="button" class="btn btn-primary"
                      style="background-color: #0983a3; margin-right: 0.5em; margin-bottom: 0.5em; border-color: black; border-width: 0.15em"
                      :disabled="recording" v-if="udpc" @click="udpc = false">
                UDP
              </button>
            </div>
            <div class="col">
              <h6>{{ $t('network_traffic.capture_card') }}:</h6>
              <select class="form-control" v-model="captureDevice">
                <option value=-1 disabled>{{ $t('network_traffic.select') }}</option>
                <option v-for="(d, index) in captureDevices" :value="index" :key="index">{{ d }}</option>
              </select>
            </div>
            <div class="col">
              <h6>{{ $t('network_traffic.capture_mode') }}:</h6>
              <select class="form-control" v-model="captureMode">
                <option value=0>{{ $t('network_traffic.capture_packets') }}</option>
                <option value=1>{{ $t('network_traffic.capture_time') }}</option>
              </select>
            </div>
            <div class="col">
              <div v-if="captureMode == 0">
                <h6>{{ $t('network_traffic.capture_packets_selected') }}:</h6>
                <input type="number" class="form-control" v-model="captureAmount">
              </div>
              <div v-if="captureMode == 1">
                <h6>{{ $t('network_traffic.capture_time_selected') }}:</h6>
                <input type="number" class="form-control" v-model="captureTime">
              </div>
            </div>
          </div>
        </div>
        <div class="card-body animate__animated animate__fadeInUp" v-if="traffic.length > 0">
          <h5>{{ $t('network_traffic.captured_packets') }}</h5>
          <div class="table-responsive" style="max-height: 20em; background-color: #383c44; border-radius: 5px">
            <table class="table table-sm table-dark table-hover table-fixed">
              <thead>
              <tr>
                <th scope="col"><h4>{{ $t('network_traffic.id') }}</h4></th>
                <th scope="col"><h4>{{ $t('network_traffic.time') }}</h4></th>
                <th scope="col"><h4>{{ $t('network_traffic.source') }}</h4></th>
                <th scope="col"><h4>{{ $t('network_traffic.destination') }}</h4></th>
                <th scope="col" style="min-width: 8em"><h4>{{ $t('network_traffic.length') }}</h4></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(t, index) in traffic" :key="index" v-on:click="viewPacket = index">
                <td>{{ t.id }}</td>
                <td>{{ t.time }}</td>
                <td>{{ t.ip_src }}</td>
                <td>{{ t.ip_dst }}</td>
                <td>{{ t.length }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <h5 style="margin-top: 0.5em">{{ $t('network_traffic.packet_info') }}</h5>
          <div v-for="(t, index) in traffic" :key="index" style="resize: vertical">
            <div
                style="max-height: 15em; background-color: #383c44; color: white; font-family: Consolas; overflow: auto; border-radius: 5px"
                v-show="viewPacket == index">
              <div class="card-body">
                <ul class="fa-ul">
                  <li>
                    <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                    {{ t.src_mac }} -> {{ t.dst_mac }}
                  </li>
                  <li v-if="t.has_ip">
                    <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                    {{ $t('network_traffic.ip_wversion') }} {{ t.ip_version }}, {{ $t('network_traffic.src') }}:
                    {{ t.ip_src }}, {{ $t('network_traffic.dst') }}: {{ t.ip_dst }}
                  </li>
                  <li v-if="t.has_tcp">
                    <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                    {{ $t('network_traffic.tcp') }}, {{ $t('network_traffic.src_port') }}: {{ t.tcp_src_port }},
                    {{ $t('network_traffic.dst_port') }}: {{ t.tcp_dst_port }}, {{ $t('network_traffic.seq') }}:
                    {{ t.tcp_seq }}, {{ $t('network_traffic.ack') }}:
                    {{ t.tcp_ack }}, {{ $t('network_traffic.len') }}:
                    {{ t.tcp_length }}
                  </li>
                  <li v-if="t.has_udp">
                    <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                    {{ $t('network_traffic.udp') }}, {{ $t('network_traffic.src_port') }}: {{ t.udp_src_port }},
                    {{ $t('network_traffic.dst_port') }}: {{ t.udp_dst_port }}, {{ $t('network_traffic.checksum') }}:
                    {{ t.udp_chksum }}, {{ $t('network_traffic.len') }}:
                    {{ t.udp_length }}
                  </li>
                  <li v-if="t.has_dns">
                    <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                    {{ $t('network_traffic.dns') }}, {{ $t('network_traffic.opcode') }}: {{ t.dns_opcode }},
                    {{ $t('network_traffic.rcode') }}: {{ t.dns_rcode }}, {{ $t('network_traffic.qdcount') }}:
                    {{ t.dns_qdcount }}, {{ $t('network_traffic.dns_question_records') }}<br>
                    <p v-for="(q, index) in t.dns_question_records" :key="index">
                      >> {{ $t('network_traffic.qname') }}: {{ q.qname }}, {{ $t('network_traffic.qtype') }}: {{
                        q.type
                      }},
                      {{ $t('network_traffic.qclass') }}: {{ q.qclass }},
                    </p>
                  </li>
                  <li v-if="t.has_icmp">
                    <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                    {{ $t('network_traffic.icmp') }}, {{ $t('network_traffic.type') }}: {{ t.icmp_type }},
                    {{ $t('network_traffic.code') }}: {{ t.icmp_code }}, {{ $t('network_traffic.chksum') }}: {{
                      t.icmp_chksum
                    }},
                    {{ $t('network_traffic.seq') }}: {{ t.icmp_seq }}, {{ $t('network_traffic.id') }}: {{ t.icmp_id }}
                  </li>
                  <li v-if="t.has_padding">
                    <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                    {{ $t('network_traffic.padding') }}: {{ t.padding_load }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <h5 style="margin-top: 0.5em">{{ $t('network_traffic.raw') }}</h5>
          <div v-for="(t, index) in traffic" :key="index">
            <div
                style="max-height: 15em; background-color: #373A3E; color: white; font-family: Consolas; overflow: auto; resize: vertical; border-radius: 5px"
                v-show="viewPacket == index">
              <div class="card-body">
                {{ t.raw }}
              </div>
            </div>
          </div>
        </div>
        <div class="card-body animate__animated animate__fadeInUp" v-else>
          <div class="row">
            <div class="col">
              <h3>{{ $t('network_traffic.emtpy_title') }}</h3>
              <p style="font-size: 1.5em">{{ $t('network_traffic.empty_comment') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import * as toastr from "@/assets/toastr";
import {api} from "@/main";
import axios from "axios";

export default {
  name: "NetworkTrafficMonitor",
  props: {
    token: String,
    id: Number,
    type: Number,
    expire: String,
    pusher: Object,
    machine_id: Number,
  },
  data() {
    return {
      traffic: [],
      tcpc: true,
      icmpc: true,
      dnsc: true,
      udpc: true,
      captureDevice: 0,
      captureDevices: [],
      captureAmount: 5,
      captureTime: 10,
      processes: [],
      captureMode: 0,
      machine: {},
      recording: false,
      showOptions: false,
      viewPacket: 0
    }
  }, methods: {
    saveDataAsJson() {
      var FileSaver = require('file-saver');
      var blob = new Blob([JSON.stringify(this.traffic)]);
      FileSaver.saveAs(blob, "captured_traffic.json");
    },
    resetCaptureOptions() {
      this.captureAmount = 5
      this.captureTime = 10
      this.captureMode = 0
      this.traffic = []
    },
    retrieveIfaces() {
      this.changeOpCode(this.machine, 4, "NET-LST-X-X-X")
    },
    setCaptureOrder() {
      // eslint-disable-next-line no-unused-vars
      var opcode = "NET-"
      var filters = []
      if (this.captureMode == 0) {
        opcode = opcode + "PCP-" + this.captureDevice + "-" + this.captureAmount + "-"
      } else if (this.captureMode == 1) {
        opcode = opcode + "TCP-" + this.captureDevice + "-" + this.captureTime + "-"
      }
      if (this.icmpc) {
        filters.push("icmp")
      }
      if (this.dnsc) {
        filters.push("dns")
      }
      if (this.tcpc) {
        filters.push("tcp")
      }
      if (this.udpc) {
        filters.push("udp")
      }
      opcode = opcode + filters.join("_")
      this.recording = true
      this.changeOpCode(this.machine, 1, opcode)
    },
    getCapturedTraffic() {
      const path = api + 'machine/' + this.machine.mac_address + '/traffic'
      axios.get(path, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.recording = false
            this.traffic = res.data.traffic
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    getInterfaces() {
      const path = api + 'machine/' + this.machine.mac_address + '/ifaces'
      axios.get(path, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.captureDevices = res.data.ifaces
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })

    },
    changeOpCode(m, n, opcode) {
      const path = api + 'machine/' + m.mac_address + '/' + n
      axios.post(path, {"opcode": opcode, "account_id": this.id}, {auth: {username: this.id, password: this.token}})
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    },
    subscribeBindChannels() {
      var channel = this.pusher.subscribe('private-' + this.machine.mac_address.replace(/:/g, ""))
      channel.bind("ifaces_update", this.getInterfaces)
      channel.bind("traffic_update", this.getCapturedTraffic)
    },
    getMachineTriggerInit(id) {
      const path = api + 'machine/' + id
      axios.get(path, {auth: {username: this.id, password: this.token}})
          .then((res) => {
            this.machine = res.data.machine
            this.retrieveIfaces()
            this.subscribeBindChannels()
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
    }
  },
  created() {
    scrollTo(0, 0)
    this.getMachineTriggerInit(this.machine_id)
  },
  mounted() {
  }
}
</script>

<style scoped>
th {
  position: sticky;
}

tbody tr:nth-child(odd) {
  background-color: #3f5259;
  color: #fff;
}

tbody tr:nth-child(even) {
  background-color: #2e373b;
  color: #fff;
}

::-webkit-scrollbar {
  height: 0.5em !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c81a1;
  border-radius: 10px;
}
</style>